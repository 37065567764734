import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { MainContextProvider } from "./context/MainContext";
import { TokenDataProvider } from "./context/TokenContext";
import { DataProvider } from "./context/DataContext";
import { NutritionDataProvider } from "./context/NutritionContext";
import { WhatsappQueryDataProvider } from "./context/WhatsappQueryContext";
import { NotificationDataProvider } from "./context/NotificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WhatsappQueryDataProvider>
      <MainContextProvider>
        <NotificationDataProvider>
          <TokenDataProvider>
            <DataProvider>
              <NutritionDataProvider>
                <App />
              </NutritionDataProvider>
            </DataProvider>
          </TokenDataProvider>
        </NotificationDataProvider>
      </MainContextProvider>
    </WhatsappQueryDataProvider>
  </React.StrictMode>
);

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Offcanvas } from 'react-bootstrap';
import MainContext from '../../../context/MainContext';

const InviteMessage = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
  };

  const [whatsappNumber, setWhatsappNumber] = useState("")
  const [templateID, setTemplateID] = useState(3)

  const { REACT_APP_FORM_URL } = process.env;

  const formlink = REACT_APP_FORM_URL;
  const amount = "75,000";

  const { ShowNotification, setIsPreLoading } = useContext(MainContext);
 
  const handleSendWhatsappinvite = () => {

    setIsPreLoading(true)

    const requestData = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: whatsappNumber,
      type: "TEMPLATE",
      template: {
        name: `deepholistics_screening_${templateID}`,
        language: {
          code: "en_US"
        },
        components: [
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: formlink
              },
              {
                type: "text",
                text: amount
              }
            ]
          }
        ]
      }
    };
  
    const headers = {
      'Authorization': 'Bearer EAAKVud5TmIIBO4wO1UqplilWjm72De1LyWQS3iixkrEhcCFcQU1fzokYThk3g6abv1RNIFQlRZCofSmef0Aqd55hO1OBB8U2ce7yklVJJyZBiLDky9eEM7nx0pKLO4cyLKrMUlCgNAlKMGk7axy0I55JKlNm9TOpWYkCW9F75aFgXiZAZBgdX8xpq93Y8LpR8OBnenS5ruBRuvMW',
      'Content-Type': 'application/json',
      'Cookie': 'ps_l=0; ps_n=0; ps_l=0; ps_n=0; ps_l=0; ps_n=0'
    };
  
    axios
      .post("https://graph.facebook.com/v19.0/246910858503415/messages", requestData, {
        headers,
      })
      .then((response) => {
        setIsPreLoading(false);
        setWhatsappNumber("")        
        ShowNotification("success", "Send message successfully")
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
      });


  }
  
  

  return (
    <div>
      <Offcanvas
        className="user-information"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Send Whatsapp</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <TextField
            className="input-field"
            label="WhatsApp Number"
            type="tel"
            variant="outlined"
            size="small"
            fullWidth
            value={whatsappNumber}
            onChange={(e) => {
              const input = e.target.value;
              const sanitizedInput = input.replace(/\D/g, "");
              if (sanitizedInput.length <= 15) {
                setWhatsappNumber(sanitizedInput);
              }
            }}
          />

           <TextField
            className="input-field my-3"
            label="WhatsApp Template ID"
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            value={templateID}
            onChange={(e) => {
              const input = e.target.value;
              const sanitizedInput = input.replace(/\D/g, "");
              if (sanitizedInput.length <= 2) {
                setTemplateID(sanitizedInput);
              }
            }}
          />

          {/* <TextField
            className="input-field my-4"
            label="Typeform link"
            type="tel"
            variant="outlined"
            size="small"
            fullWidth
            value={"https://deepholistics.typeform.com/to/RS0VInCg"}
            disabled
          /> */}

          <div className="my-4">
            <p>
              <b>Whatsapp message format</b>
            </p>
            <p className="mb-0">
              <small>Hello!</small>
            </p>
            <p>
              <small>
                Thank you for visiting us at The Synapse Conclave and expressing
                your interest in the Human Token. To confirm your eligibilty for
                the program, click on the link{" "}
                <a href={formlink}>
                {formlink}
                </a>{" "}
                to begin the screening process. We appreciate your time and are
                excited to be a part of your wellness journey!
              </small>
            </p>
            <p>
              <small>
                <b>PS:</b> As promised, the Human Token will be at a special
                price of
                <b> ₹{amount}</b> just for you!
              </small>
            </p>
          </div>

          <div className='text-end'>
            <Button
              variant="contained"
              color={"success"}
              onClick={handleSendWhatsappinvite}
              className="my-1"
            >
              <FontAwesomeIcon icon={faPaperPlane} className="mr-1" />
              Send message
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default InviteMessage
import { faUtensils, faWheatAwn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Accordion, Card, Col, Offcanvas, Row } from 'react-bootstrap';
import "../food/NutritionView.scss";

const MealView = ({ show, setShowMealFullView, mealFullData }) => {

  const handleClose = () => {
    setShowMealFullView(false);
  };

  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (eventKey) => {
    setActiveAccordion(eventKey === activeAccordion ? null : eventKey);
  };


  const NutrientComponent = ({servingData}) => {
    const nutrientsLeft = [
      { name: "Total Fat", key: "fat", unit: "g" },
      { name: "Saturated Fat", key: "saturated_fat", sub: true, unit: "g" },
      { name: "Trans Fat", key: "trans_fat", sub: true, unit: "g"   },
      { name: "Polyunsaturated Fat", key: "polyunsaturated_fat", sub: true,  unit: "g" },
      { name: "Monounsaturated Fat", key: "monounsaturated_fat", sub: true,  unit: "g"   },
      { name: "Cholesterol", key: "cholesterol", unit: "mg" },
      // { name: "Sodium", key: "sodium", unit: "mg" },
      { name: "Total Carbohydrate", key: "carbohydrate", unit: "g" },
      { name: "Dietary Fiber", key: "fiber", unit: "g", sub: true },
      { name: "Sugars", key: "sugar", unit: "g", sub: true },
    ];

    const nutrientsRight = [
      { name: "Sodium", key: "sodium", unit: "mg" },
      { name: "Protein", key: "protein", unit: "g"   },
      { name: "Vitamin D", key: "vitamin_d", unit: "mcg" },
      { name: "Calcium", key: "calcium", unit: "mg" },
      { name: "Iron", key: "iron", unit: "mg" },
      { name: "Potassium", key: "potassium", unit: "mg" },
      { name: "Vitamin A", key: "vitamin_a", unit: "mcg" },
      { name: "Vitamin C", key: "vitamin_c", unit: "mg" },
    ];
    return (
      <Row className='m-0'>
        <NutrientColumn nutrients={nutrientsLeft} servingData={servingData}/>
        <NutrientColumn nutrients={nutrientsRight} servingData={servingData}/>
      </Row>
    );
  };

  const Nutrient = ({ name, value, percentage, unit = "mg", sub = false }) => (
    <div className={`flex-nutrient${sub ? " sub" : ""}`}>
      <span>{name}  &emsp;  </span>
      <span>{(value !== null) ? `${parseFloat(value).toFixed(1)} ${unit}` : "-"}</span>
    </div>
  );

  const NutrientColumn = ({ nutrients, servingData }) => {
    const { number_of_units, cc_serving } = servingData;

    return (
      <Col md={6} className="m-0 px-1">
        {nutrients.map((nutrient) => (
          <React.Fragment key={nutrient.key}>
            <Nutrient
              name={nutrient?.name}
              value={
                (number_of_units / cc_serving?.number_of_units) *
                cc_serving?.[nutrient.key]
              }
              unit={nutrient.unit}
              sub={nutrient.sub}
              // value={ (servingData?.number_of_units / servingData?.cc_serving?.number_of_units) * servingData?.cc_serving?.[nutrient.key] }
              // percentage={((selectedServingAmount * selectedFoodServing?.[nutrient.key])/ selectedFoodMicros?.[nutrient.key] || 0)*100}
            />
            {nutrient.hr && <hr className="hr-1" />}
          </React.Fragment>
        ))}
      </Col>
    );
  };

  return (
    <div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="mb-0">Meal Info</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="ScrollDesign Meal-View">
          <div>
            <h4>{mealFullData?.name}</h4>
            <p className="mb-0">{mealFullData?.description}</p>
          </div>

          <Card className="my-3">
            <Card.Body
              style={{
                background: "linear-gradient(45deg, #e5e5e5, transparent)",
              }}
            >
              {mealFullData?.cc_meal_serving?.map((meal, index) => (
                <div key={index} className="mb-2 meal-food-item">
                  <div>
                    <Row>
                      <Col md={8}>
                        <h4>{meal?.cc_serving?.cc_food?.name}</h4>
                      </Col>
                      <Col md={4}>
                        <h4>
                          <small className="mb-0 float-end">
                            {"[Source: "}
                            {meal?.cc_serving?.cc_food?.source}
                            {"]"}
                          </small>
                        </h4>
                      </Col>
                    </Row>

                    <p className="mb-0 d-flex justify-content-between">
                      <span style={{ color: "green" }}>
                        <FontAwesomeIcon icon={faUtensils} />{" "}
                        {meal?.number_of_units}{" "}
                        {meal?.cc_serving?.measurement_description}
                      </span>
                      <span style={{ color: "red" }}>
                        <FontAwesomeIcon icon={faWheatAwn} />{" "}
                        {parseFloat((meal?.number_of_units /
                          meal?.cc_serving?.number_of_units) *
                          meal?.cc_serving?.calories).toFixed(2)}
                        {" Cals"}
                      </span>
                    </p>
                  </div>
                  <Accordion
                    activeKey={activeAccordion}
                    onSelect={handleAccordionClick}
                  >
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>View Micronutrients Value</Accordion.Header>
                      <Accordion.Body>
                        <section className="d-flex justify-content-between align-items-center px-2">
                          <span style={{ fontSize: "14px" }}>Serving Size</span>
                          <span style={{ fontSize: "22px" }}>
                            {meal?.number_of_units}{" "}
                            {meal?.cc_serving?.measurement_description}
                          </span>
                        </section>
                        <section className="d-flex justify-content-between align-items-center px-2">
                          <div style={{ fontSize: "14px" }}>
                            Amount per Serving
                            <br />
                            <span style={{ fontSize: "22px" }}>Cals</span>
                          </div>
                          <div style={{ fontSize: "30px" }}>
                            {(meal?.number_of_units /
                              meal?.cc_serving?.number_of_units) *
                              meal?.cc_serving?.calories}
                          </div>
                        </section>
                        <section className="d-flex justify-content-between align-items-center px-2">
                          <span></span>
                          <span style={{ fontSize: "10px" }}>% RDI*</span>
                        </section>
                        <NutrientComponent servingData={meal} />
                        <div
                          className="text-end border-none"
                          style={{ fontSize: "10px" }}
                        >
                          * RDI value based on 2000 Cal diet for adult male
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default MealView
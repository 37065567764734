import { createContext, useState, useContext } from "react";
import MainContext from "./MainContext";

const NotificationContext = createContext({});

export const NotificationDataProvider = ({ children }) => {

  // const { setIsPreLoading } = useContext(MainContext);

  const [unreadCount, setUnreadCount] = useState(5)


  return (
    <NotificationContext.Provider
      value={{
        unreadCount, setUnreadCount     
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;

import { createContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { getUserFullProfile, getUserProfile } from "../utils/preTokenApi";

const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {

  const [accessToken, setAccessToken]= useState("");
  const [isInitPreLoading, setIsInitPreLoading] = useState(true); //Initial Loader
  const [isPreLoading, setIsPreLoading] = useState(false);  // Preloading Other

  /*  */
  const [activeLink, setActiveLink] = useState('');

  const [foodProfileId, setFoodProfileId] = useState("");
  const [userProfileData, setUserProfileData] = useState([]);
  const [userRole, setUserRole] = useState("");

  const { REACT_APP_CLIENT_ID } = process.env;

  const headers = {
    access_token: accessToken,
    user_timezone: "Asia/Calcutta",
    "Content-Type": "application/json",
    client_id: REACT_APP_CLIENT_ID,
  };
 
  // useEffect(() => {
  //   if(accessToken){
  //     const decodedToken = jwtDecode(accessToken);
  //   }
  // }, [accessToken]);

  const getProfile = async () =>{    
    try {
      const getUserProfileResponse = await getUserProfile();      
      setUserProfileData(getUserProfileResponse.data?.profile);
      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
    }

  }

  const getFullProfile = async () =>{    
    try {
      const getFullProfileResponse = await getUserFullProfile();   
      setFoodProfileId(getFullProfileResponse?.data?.cc_profile?.id);  
      setUserRole(getFullProfileResponse?.data?.role_id)
      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
    }

  }
  
  useEffect(() => {
    if((new Cookies()).get('access_token')){      
      setAccessToken((new Cookies()).get('access_token'))
    }
  },[]);

  const ShowNotification = (type, message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  
  useEffect(() => {    
    return async () => {
      setTimeout(() => {
        setIsInitPreLoading(false)
      }, 2000);
    };
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      getProfile();
      getFullProfile()
    }
  }, [accessToken]);

  return (
    <MainContext.Provider
      value={{
        foodProfileId,
        userProfileData,
        ShowNotification,
        accessToken,
        setAccessToken,

        isInitPreLoading,
        isPreLoading,
        setIsPreLoading,
        setIsInitPreLoading,
        activeLink,
        setActiveLink,

        headers,
        userRole, setUserRole 
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;

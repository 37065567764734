import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;

const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      access_token: cookies.get("access_token"),
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};


const nutritionApi = createAxiosInstance(REACT_APP_BASE_URL);
//Interceptors
const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["access_token"] = token; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(nutritionApi);

//Get FoodList
export const getCCFoodList = (urlParam, accessToken) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .get(`/v2/calorie-counter/food/getFoodList${urlParam}`, {
        headers: {
          ...nutritionApi.defaults.headers,
          access_token: accessToken,
        },
      })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

//Get Food Nutrients
export const getCCFoodNutirentsData = (food_id, food_profile_id) =>
  new Promise((resolve, reject) => {

    const requestData = {
      food_id: food_id,
      food_profile_id: food_profile_id,
      is_admin: true
    };

    nutritionApi
      .post(`/v1/calorie-counter/food/get/`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

//Get Food MealList
export const getCCMealData = (requestData) =>
  new Promise((resolve, reject) => {
    //${urlParam}
    nutritionApi
      .post(`/v1/calorie-counter/meal/get`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

//Get Food Intake Summary List 
export const getFoodIntakeSummary = (requestParam) =>
  new Promise((resolve, reject) => {
    //
    nutritionApi
      .post(`/v3/calorie-counter/meal/get${requestParam}`, {})
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

//Food Search - from FATSECRET
export const suggestFoodSearch = (requestData) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .post(`/v1/calorie-counter/food/search`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

//Food -Add
export const addFood = (requestData) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .post(`/v2/calorie-counter/food/add`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

//Food -Update
export const updateFood = (requestData, requestId) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .put(`/v2/calorie-counter/food/${requestId}`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

//Food -Update
export const updateFoodGI = (requestData, requestId) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .patch(`/v2/calorie-counter/food/${requestId}`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

//Meal -Add
export const addMeal = (requestData) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .post(`/v1/calorie-counter/meal/create`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

//Meal -Update
export const updateMeal = (requestData) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .post(`/v1/calorie-counter/meal/update`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

//FoodIntake -Update
export const getListFoodIntake = (requestData) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .get(`/v3/int/human-token/nutrition/food-intake/list`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

//FoodIntake -Update
export const updateFoodIntake = (requestData) =>
  new Promise((resolve, reject) => {
    nutritionApi
      .put(`/v3/int/human-token/nutrition/food-intake`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });


export { nutritionApi };

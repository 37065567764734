import { faAdd, faCartShopping, faClose, faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, IconButton, InputBase, Paper, TextField } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert, Col, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import { addMeal, getCCFoodNutirentsData, suggestFoodSearch, updateMeal } from '../../../utils/nutritionApi';
import axios from 'axios';
import "../food/NutritionView.scss";
import MainContext from '../../../context/MainContext';
import NutritionContext from '../../../context/NutritionContext';

const CreateMeal = ({
  show,
  setShowMealCreationView,
  mealFullData,
  setIsMealEdit,
  isMealEdit,
  fetchMealListData,
}) => {
  
  const handleClose = () => {
    setShowMealCreationView(false);
    setIsMealEdit(false);
    setInputMealName("");
    setInputMealDesc("");
    setInputMealId("");
    setMealFoods([]);
  };

  const { foodServingUnitArray } =
    useContext(NutritionContext);
  const { headers, ShowNotification, foodProfileId, setIsPreLoading } =
    useContext(MainContext);
  const { REACT_APP_BASE__URL } = process.env;

  const [suggestionsShown, setSuggestionsShown] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [inputMealName, setInputMealName] = useState("");
  const [inputMealDesc, setInputMealDesc] = useState("");
  const [inputMealId, setInputMealId] = useState("");

  const divRef = useRef();

  const [mealFoods, setMealFoods] = useState([]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setSuggestionsShown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  const handleInputChange = async (e) => {
    setIsPreLoading(true);
    const inputValue = e.target.value;
    setSelectedInput(inputValue);

    //Updated one - from FATSECRET
    const data = {
      search_expression: inputValue,
      food_profile_id: foodProfileId,
      is_admin:true
    };

    try {
      if (inputValue?.length > 0) {
        const CCFoodSearchResponse = await suggestFoodSearch(data);
        let responseData =
          CCFoodSearchResponse?.data?.search_result?.food?.foods;
        setSuggestions(responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsPreLoading(false);
    } finally {
      setIsPreLoading(false);
    }

    if (!suggestionsShown && inputValue.trim() !== "") {
      setSuggestionsShown(true);
    }

    //Exsiting one - from cc_food
    // try {
    //   const CCFoodListResponse = await getCCFoodList(
    //     `?limit=10&offset=0&search=${inputValue}&order=ASC&orderBy=name`,
    //     accessToken
    //   );
    //   let responseData = CCFoodListResponse?.data?.cc_food;
    //   setSuggestions(responseData);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   // setIsPreLoading(false);
    // } finally {
    //   // setLoading(false);
    // }
  };

  const handleSuggestionClick = async (suggestion) => {

    let foodSuggestion = [];

    //Updated Code from get FatSecret
    try {
      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
        suggestion?.food_id,
        foodProfileId
      );

      let responseData = CCFoodNutrientsResponse?.data;

      foodSuggestion = responseData?.cc_food;
      //Existing
      let findSuggestion = mealFoods?.filter(
        (item) => item?.id === foodSuggestion?.id
      );

      if (
        findSuggestion?.length === 0 ||
        findSuggestion?.length === undefined
      ) {
        setSuggestionsShown(false);
        setSelectedInput("");

        let initDefault = foodSuggestion?.cc_servings?.find(
          (item) => item.is_default
        );

        const updatedFoodSuggestion = {
          ...foodSuggestion,
          selected_serving: initDefault,
          is_edited: true,
          unit_count: 1,
          number_of_units: initDefault["number_of_units"],
        };

        setMealFoods((prevMealFoods) => [
          ...(prevMealFoods || []),
          updatedFoodSuggestion,
        ]);
      } else {
        ShowNotification("info", "Food already Added...");
        setSuggestionsShown(false);
      }
      //Existing
      
    } catch (error) {
      console.error('Error fetching data:', error.response ? error.response.data : error.message);
      setIsPreLoading(false);
    } finally {
      setIsPreLoading(false);
    }
    
    
  };

  const handleServingUnitChange = (unitValue, foodId) => {
    setMealFoods(
      mealFoods.map((food) => {
        if (food.id === foodId) {
          return {
            ...food,
            number_of_units: unitValue,
            unit_count: unitValue / food.selected_serving.number_of_units,
          };
        }
        return food;
      })
    );
  };

  const handleServingOptionChange = (servingId, foodId) => {

    let requestData = mealFoods.map((food) => {

      if (food?.id === foodId){

        let selectedServing = food?.cc_servings?.find(
          (item) => item.id === servingId
        );
        
        return {
          ...food,
          selected_serving: selectedServing,
          number_of_units: selectedServing?.number_of_units,
          unit_count: 1, // selectedServing["number_of_units"] / selectedServing["number_of_units"],
        };
      } else {
        return food;
      }
    });

    setMealFoods(requestData);
  };

  const handleGetNutrients = async (foodId) => {
    setIsPreLoading(true);
    try {      
      const CCFoodNutrientsResponse = await getCCFoodNutirentsData(
        foodId,
        foodProfileId
      );

      let responseData = CCFoodNutrientsResponse?.data;

      const updatedData = mealFoods.map((food) => {
        if (food.food_id === foodId) {
          return {
            ...food,
            cc_servings: responseData?.cc_food?.cc_servings,
            is_edited: true,
          };
        }
        return food;
      });

      setMealFoods(updatedData);
      setIsPreLoading(false);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      setIsPreLoading(false);
    } finally {
    }
  };

  const handleRemoveFood = (dataIndex) => {
    if (!isMealEdit) {
      // if (mealFoods?.length) {
      //   const updatedData = mealFoods.filter((_, index) => index !== dataIndex);
      //   setMealFoods(updatedData);
      // }
      setMealFoods(
        mealFoods?.length
          ? mealFoods.filter((_, index) => index !== dataIndex)
          : []
      );
    } else {
      const updatedData = mealFoods.map((food, index) => {
        if (index === dataIndex) {
          return {
            ...food,
            to_be_deleted: true,
          };
        }
        return food;
      });
      
      setMealFoods(updatedData);
    }

  };

  const handleAddRemovedFood = (dataIndex) => {
    if (isMealEdit) {
      const updatedData = mealFoods.map((food, index) => {
        if (index === dataIndex) {
          return {
            ...food,
            to_be_deleted: false,
          };
        }
        return food;
      });

      setMealFoods(updatedData);
    }
  };

  const handleSaveMeal = async () => {

    setIsPreLoading(true);
    let foodObj = mealFoods?.map((food) => {
      return {
        serving_id: food?.selected_serving?.id,
        serving_id_meta: food?.selected_serving?.serving_id,
        number_of_units: food?.number_of_units,
        unit_count: food?.unit_count,
        food_id: food?.id,
        food_id_meta: food?.food_id,
      };
    });

    let requestData = {
      name: inputMealName,
      description: inputMealDesc,
      food_profile_id: null, //foodProfileId,
      servings: foodObj,
      is_admin:true
    };

    try {
      const addMealResponse = await addMeal(requestData);      
      fetchMealListData();
      setMealFoods([]);
      setInputMealName("");
      setInputMealDesc("");
      handleClose();
      setIsPreLoading(false);
      ShowNotification("success", "Meal Added SuccessFully");

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPreLoading(false);
    }

    /* Old */
    // const apiUrl = `${REACT_APP_BASE__URL}/v1/calorie-counter/meal/create`;

    // axios
    //   .post(apiUrl, requestData, { headers })
    //   .then((response) => {
    //     // const responseData = response?.data?.data?.cc_food;
        
    //     fetchMealListData();
    //     setMealFoods([]);
    //     setInputMealName("");
    //     setInputMealDesc("");
    //     handleClose();
    //     setIsPreLoading(false);
    //     ShowNotification("success", "Meal Added SuccessFully");
    //   })
    //   .catch((error) => {
    //     console.error(error.response.data.message);
    //     setIsPreLoading(false);
    //     ShowNotification("error", "Please Check Foods");
    //   });
  };

  const handleUpdateMeal = async () => {
    
    setIsPreLoading(true);
    let foodObj = mealFoods?.map((food) => {
      return {
        serving_id: food?.selected_serving?.id,
        serving_id_meta: food?.selected_serving?.serving_id,
        number_of_units: food?.number_of_units,
        unit_count: food?.unit_count,   //Optional
        food_id: food?.id,
        food_id_meta: food?.food_id,
        to_be_deleted: !!food?.to_be_deleted //? true : false,
      };
    });

    const foodRemObj = mealFullData?.cc_meal_serving?.map(serving => {
      const selectedServing = foodObj?.find(item => item?.serving_id === serving?.serving_id);
    
      return selectedServing
        ? null
        : {
            serving_id: serving?.serving_id,
            serving_id_meta: serving?.cc_serving?.serving_id,
            number_of_units: serving?.number_of_units,
            food_id: serving?.cc_serving?.cc_food?.id,
            food_id_meta: serving?.cc_serving?.cc_food?.food_id,
            to_be_deleted: true,
          };
    }).filter(Boolean);
    
    let combinedServingArray = [...(foodRemObj || []), ...foodObj];

    let requestData = {
      meal_id: inputMealId,
      name: inputMealName,
      description: inputMealDesc || "",
      food_profile_id: null, //foodProfileId,
      servings: combinedServingArray,
      is_admin:true
    };

    try {
      const updateMealResponse = await updateMeal(requestData); 

      fetchMealListData();
      setMealFoods([])
      setInputMealName("")
      setInputMealDesc("")
      setInputMealId("")
      handleClose();
      setIsPreLoading(false);
      ShowNotification("success", "Meal Updated SuccessFully");

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPreLoading(false);
    }

    /* Old */
    // const apiUrl = `${REACT_APP_BASE__URL}/v1/calorie-counter/meal/update`;

    // axios
    //   .post(apiUrl, requestData, { headers })
    //   .then((response) => {
    //     // const responseData = response?.data?.data?.cc_food;
    //     fetchMealListData();
    //     setMealFoods([])
    //     setInputMealName("")
    //     setInputMealDesc("")
    //     setInputMealId("")
    //     handleClose();
    //     setIsPreLoading(false);
    //     ShowNotification("success", "Meal Updated SuccessFully");
    //   })
    //   .catch((error) => {
    //     console.error(error.response.data.message);
    //     setIsPreLoading(false);
    //     ShowNotification("error", "Please Check Meal Data");
    //   });
  };

  
  useEffect(() => {
    if (isMealEdit) {

      let convertData = mealFullData?.cc_meal_serving?.map(
        ({ cc_serving, number_of_units }) => {
          const { cc_food, ...selectedServing } = cc_serving || {};

          return {
            ...cc_food,
            selected_serving: selectedServing,
            cc_servings: [selectedServing],
            number_of_units: number_of_units,
            unit_count: (number_of_units / selectedServing?.number_of_units),
            is_edited: false,
          };
        }
      );

      setInputMealName(mealFullData?.name);
      setInputMealDesc(mealFullData?.description);
      setInputMealId(mealFullData?.id);

      setMealFoods(convertData);
    }
  }, [isMealEdit]);

  /* Image Upload */

  // const [selectedFile, setSelectedFile] = useState(null);
  // const [imageUrl, setImageUrl] = useState('');

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedFile(file);

  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setImageUrl(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  // const handleUpload = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('image', selectedFile);

  //     const response = await axios.post('API_URL', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     console.info('Image uploaded successfully:', response.data);
  //   } catch (error) {
  //     console.error('Error uploading image:', error);
  //   }
  // };

  return (
    <div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="mb-0">Meal {isMealEdit ? "Update" : "Creation"}</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="ScrollDesign Meal-Creation">
          <div>
            <TextField
              label="Meal Name"
              className="mb-3"
              variant="outlined"
              size="small"
              fullWidth
              value={inputMealName}
              onChange={(e) => {
                setInputMealName(e.target.value);
              }}
            />
            <TextField
              label="Meal Description"
              className="mb-3"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={2}
              value={inputMealDesc}
              onChange={(e) => {
                setInputMealDesc(e.target.value);
              }}
            />

            {/* <input type="file" onChange={handleFileChange} />
            {imageUrl && (
              <div>
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  style={{ maxWidth: "200px" }}
                />
              </div>
            )} */}
          </div>

          <div className="mb-3">
            <div className="text-center border rounded border-success p-2 mb-3 bg-success text-white">
              <FontAwesomeIcon icon={faCartShopping} /> Meal Cart
            </div>

            <div className="search-section" ref={divRef}>
              <Paper
                className="border border-secondary"
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton sx={{ p: "5px" }} aria-label="menu">
                  <FontAwesomeIcon icon={faSearch} />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Food Name"
                  inputProps={{ "aria-label": "Search Food Name" }}
                  value={selectedInput}
                  onChange={handleInputChange}
                />
              </Paper>
              <Dropdown
                show={
                  suggestionsShown &&
                  selectedInput?.length > 0 &&
                  suggestions?.length > 0
                }
              >
                <Dropdown.Menu className='ScrollDesign'>
                  {suggestions?.map((suggestion, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      <div className="search-dropdown">
                        <span className='food-name'>
                          {suggestion?.food_name} <br />{" "}
                          <small>
                            {suggestion?.servings[0]?.number_of_units}{" "}
                            {suggestion?.servings[0]?.measurement_description}
                          </small>
                        </span>
                        <span>{suggestion?.servings[0]?.calories} Cals</span>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {suggestionsShown && suggestions?.length <= 0 && (
                <Alert
                  variant={"warning"}
                  className="my-3 alert-no-suggestions"
                >
                  Please add food from FATSECRET or Manual
                </Alert>
              )}
            </div>

            {mealFoods?.map((meal, index) => (
              <div key={index} className="mt-3">
                <Paper
                  component="form"
                  className={`mb-3 p-2 w-100 food-item ${
                    meal?.to_be_deleted ? "deleted-meal" : ""
                  } `}
                >
                  {meal?.to_be_deleted ? (
                    <Fab
                      size="small"
                      color="success"
                      aria-label="add"
                      className="remove-food"
                      onClick={() => {
                        handleAddRemovedFood(index);
                      }}
                    >
                      <FontAwesomeIcon icon={faAdd} />
                    </Fab>
                  ) : (
                    <Fab
                      size="small"
                      color="error"
                      aria-label="add"
                      className="remove-food"
                      onClick={() => {
                        handleRemoveFood(index);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </Fab>
                  )}
                  <Row>
                    <Col md={7}>
                      <h4>{meal?.name}</h4>
                      <p>
                        {meal?.unit_count *
                          meal?.selected_serving?.metric_serving_amount}{" "}
                        {meal?.selected_serving?.metric_serving_unit} -{" "}
                        {parseFloat(
                          meal?.unit_count * meal?.selected_serving?.calories
                        ).toFixed(2)}{" "}
                        Cals
                      </p>
                    </Col>
                    <Col md={5} className="text-end m-auto">
                      <span className="d-flex w-100">
                        <div className="w-50 mx-2 text-center">
                          <p>Unit</p>
                          <Form.Select
                            size="sm"
                            className="mx-2"
                            disabled={meal?.to_be_deleted}
                            onChange={(e) => {
                              handleServingUnitChange(e.target.value, meal?.id);
                            }}
                            value={
                              meal?.unit_count *
                              meal?.selected_serving?.["number_of_units"]
                            }
                          >
                            {foodServingUnitArray?.map((option, index) => (
                              <option key={index} value={option}>
                                {parseFloat(option)}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="w-50 mx-2 text-center">
                          <p>Serving</p>
                          <Form.Select
                            size="sm"
                            disabled={meal?.to_be_deleted}
                            onClick={(e) => {
                              if (!meal?.is_edited) {
                                handleGetNutrients(meal?.food_id);
                              }
                            }}
                            onChange={(e) => {
                              handleServingOptionChange(
                                e.target.value,
                                meal?.id
                              );
                            }}
                            value={meal?.selected_serving?.["id"]}
                          >
                            {meal?.cc_servings?.map((option, index) => (
                              <option key={index} value={option?.id}>
                                {option?.measurement_description}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Paper>
              </div>
            ))}

            <div>
              <Fab
                color="primary"
                aria-label="add"
                className="save-meal-btn"
                onClick={isMealEdit ? handleUpdateMeal : handleSaveMeal}
              >
                <FontAwesomeIcon icon={faSave} />
              </Fab>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CreateMeal
import React, { useState, useContext } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
// import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { preTokenV1Api } from "../utils/preTokenApi";
import preloaderGif from "../../assets/img/preloader-2.gif";
import "./Login.css";
import { preTokenApi } from "../../utils/preTokenApi";
import { jwtDecode } from "jwt-decode";
import MainContext from "../../context/MainContext";

const Login = ({ setSigned }) => {
  
  const navigate = useNavigate();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
 
  const cookies = new Cookies();
  const { setAccessToken, ShowNotification } = useContext(MainContext);

  const [passwordType, setPasswordType] = useState("password");
  
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const validateEmail = (inputText) => {
    //const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleSubmit = async () => {
    
    if (validateEmail(email)) {
      setIsLoginPreLoading(true);
      preTokenApi.post("/v1/user/login", {
        login_type: "email",
        email,
        password,
        app_version: "1.0.0",
        mobile_type: "android",
        mobile_details: {
          device: "Test Device",
          model_no: "8T",
          manufacturer: "OnePlus",
          os_build_version: "test_version",
          mobile_unique_id: "123123123",
        },
      })
        .then((response) => {
          if (response.data.status === "success") {

            let responseToken = response.data.data.access_token
            
            if (responseToken) {
              const decodedToken = jwtDecode(responseToken);
              // console.log(decodedToken?.role)
              if (decodedToken?.role) {
                cookies.set("access_token", responseToken, {
                  path: "/",
                  maxAge: 604800,
                });

                setAccessToken(response.data.data.access_token);
                setSigned(true);
                setIsLoginPreLoading(false);
                navigate("/");
              } else {
                ShowNotification("error", "User not Authorized");
                setIsLoginPreLoading(false);
              }
            }
            
          }
        })
        .catch((error) => {
          //Swal.fire(error.message, "", "warning");
          // Swal.fire("Please check your Credentials", "", "warning");
          ShowNotification("error", "Please enter valid Credentials.")
          setIsLoginPreLoading(false)
        });
    }else{
      ShowNotification("warning", "Please enter valid credentials.")
    }
  };

  //Initial Preloader
  const [isLoginPreLoading, setIsLoginPreLoading] = useState(false); //Initial Loader

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="Loading..." />
      </div>
    );
  };

  /* Login/Signup Panel */

  const [activePanel, setActivePanel] = useState(false);

  const handlePanelToggle = (status) => {
    setActivePanel(status);
  };

  return (
    <div>
      {isLoginPreLoading && <Preloader />}
      <div className="login-container">
        <div
          className={`container ${activePanel ? "active" : ""}`}
          id="container"
        >
          <div className="form-container sign-up">
            <form>
              <h1>Create Account</h1>

              <span>or use your email for registration</span>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <button type="submit">Sign Up</button>
            </form>
          </div>
          <div className="form-container sign-in">
            <form>
              <h1 className="log-in-text">Log In </h1>

              <span className="mb-2 log-in-desc">Use your E-mail and Password</span>

              <Form.Group
                className="mb-2 text-start w-100"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => handleChange(e, setEmail)}
                />
              </Form.Group>
              <Form.Group
                className="mb-2 text-start w-100"
                controlId="formBasicPassword"
              >
                <InputGroup>
                  <Form.Control
                    type={passwordType}
                    placeholder="Password"
                    onChange={(e) => handleChange(e, setPassword)}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                  />
                  <InputGroup.Text
                    onClick={togglePassword}
                    style={{ cursor: "pointer" }}
                  >
                    {passwordType === "password" ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Button variant="primary" onClick={handleSubmit}>
                Sign In
              </Button>
            </form>
          </div>
          <div className="toggle-container">
            <div className="toggle">
              <div
                className={`toggle-panel toggle-left ${
                  activePanel ? "active" : ""
                }`}
              >
                <h1>Welcome Back!</h1>
                <p>Enter your personal details to use all site features</p>
                <button
                  className="hidden"
                  onClick={() => handlePanelToggle(false)}
                  id="login"
                >
                  Sign In
                </button>
              </div>
              <div
                className={`toggle-panel toggle-right ${
                  activePanel ? "active" : ""
                }`}
              >
                <img
                  src={require("../../assets/img/dh-logo-light-1.png")}
                  alt=""
                  className="login-logo"
                />
                 <img
                  src={require("../../assets/img/dh-logo-text-light.png")}
                  alt=""
                  className="login-logo-text"
                />
                {/* <h1>Deep Holistics</h1>
                <p>Look - Feel - Perform</p> */}
                {/* <button
                  className="hidden"
                  onClick={() => handlePanelToggle(true)}
                  id="register"
                >
                  Sign Up
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;

import { Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import './HtmlEditor.scss'

function HtmlEditor() {
  const [html, setHTML] = useState("<h1></h1>");
  const [css, setCSS] = useState("h1 {\n}");

  const [outputTemplate, setOutputTemplate] = useState('')

  useEffect(() => {
    const iframe = document.querySelector(".output iframe");
    const contents = iframe.contentDocument;
    const body = contents.body;
    const styleTag = contents.createElement("style");
    contents.head.appendChild(styleTag);

    body.innerHTML = html;
    styleTag.textContent = css;
  }, [html, css]);

  const handleHTMLChange = (e) => {
    setHTML(e.target.value);
  };

  const handleCSSChange = (e) => {
    setCSS(e.target.value);
  };

  useEffect(() => {
    let htmlCode = `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">    
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Varela+Round&display=swap" rel="stylesheet">
        
        <title></title>
        <!--[if mso]>
                <noscript>
                    <xml>
                        <o:OfficeDocumentSettings>
                            <o:PixelsPerInch>96</o:PixelsPerInch>
                        </o:OfficeDocumentSettings>
                    </xml>
                </noscript>
        <![endif]-->
        <style>${css}</style>
        </head>        
        <body class="email-template-section">
            <div class="main-content">${html}</div>
        </body>    
    </html>`;
  }, [html, css]);

  return (
    <section className="html-editor">
      <Grid container spacing={2}>
        <Grid item md={5}>
          <div className="html-box">           
            <TextField
              id="html"
              className="editor-input"
              label="HTML"

              value={html}
              multiline
              onChange={handleHTMLChange}
            />
          </div>
          <div className="css-box">
            <TextField
              id="html"
              className="editor-input"
              label="CSS"
              value={css}
              multiline
              onChange={handleCSSChange}
            />
           
          </div>
        </Grid>
        <Grid item md={7}>
          <div className="output grid">
            <iframe title="Output"></iframe>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}

export default HtmlEditor;

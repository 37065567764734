import {
  Avatar,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Card, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faArrowsRotate,
  faBarcode,
  faCircleCheck,
  faEnvelopeOpenText,
  faLink,
  faPhoneSquareAlt,
  faPalette,
  faRing,
  faSave,
  faSuitcaseMedical,
  faPrescriptionBottleMedical,
  faTruckMedical,
  faEdit,
  faClose,
  faBoxesPacking,
  faList,
  faUserPen,
  faIdCardClip,
} from "@fortawesome/free-solid-svg-icons";

import MainContext from "../../../context/MainContext";
import { createDiKit, updateDiKit, updateDiOrder } from "../../../utils/TrackingAPI";

const PremiumUserInfo = ({
  show,
  setShow,
  selectedUserinfo,
  setSelectedUserinfo,
  getVerificationUserData,
  getPremiumUserListData
}) => {
  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const handleClose = () => {
    setSelectedUserinfo([]);

    setIsDiagnosticsEdit(false)
    setIsDeviceEdit(false)
    setIsPickupEdit(false)

    setShow(false);
  };

  //Set Verification User Conditions
  useEffect(() => {
  },[selectedUserinfo]);

  const [salivaBarcode, setSalivaBarcode] = useState("")
  const [stoolBarcode, setStoolBarcode] = useState("")
  const [kitTrackingLink, setKitTrackingLink] = useState("")
  const [kitOrderID, setKitOrderID] = useState("")
  const [kitShipmentStatus, setKitShipmentStaus] = useState("")

  const [deviceKitTrackingLink, setDeviceKitTrackingLink] = useState("")
  const [deviceKitOrderID, setDeviceKitOrderID] = useState("")
  const [deviceKitShipmentStatus, setDeviceKitShipmentStaus] = useState("")

  const [orderTrackingLink, setOrderTrackingLink] = useState("")
  // const [orderOrderId, setOrderOrderId] = useState("")
  const [orderShipmentStatus, setOrderShipmentStaus] = useState("")
  

  /* Edit */

  const [isDiagnosticsEdit, setIsDiagnosticsEdit] = useState(false)
  const [isDeviceEdit, setIsDeviceEdit] = useState(false)
  const [isPickupEdit, setIsPickupEdit] = useState(false)

  const handleDiagnosticsKitEditClick = () =>{
    setIsDiagnosticsEdit(true);
    
    setSalivaBarcode(selectedUserinfo?.kits?.saliva_kit?.barcode)
    setStoolBarcode(selectedUserinfo?.kits?.stool_kit?.barcode)
    setKitTrackingLink(selectedUserinfo?.kits?.stool_kit?.order_tracking_link)
    setKitOrderID(selectedUserinfo?.kits?.stool_kit?.order_id)
    setKitShipmentStaus(selectedUserinfo?.kits?.stool_kit?.order_status)

  }

  const handleDeviceKitEditClick = () =>{
    setIsDeviceEdit(true);
    
    setDeviceKitTrackingLink(selectedUserinfo?.kits?.device_kit?.order_tracking_link)
    setDeviceKitOrderID(selectedUserinfo?.kits?.device_kit?.order_id)
    setDeviceKitShipmentStaus(selectedUserinfo?.kits?.device_kit?.order_status)
  }

  const handlePickupEditClick = () =>{
    setIsPickupEdit(true);    
    setOrderTrackingLink(selectedUserinfo?.orders?.saliva?.tracking_link)
    // setOrderOrderId(selectedUserinfo?.orders?.saliva?.order_id)
    setOrderShipmentStaus(selectedUserinfo?.orders?.saliva?.status)

  }

  /* DeviceKit */
  const handleCreateDeviceKit = async () =>{
    setIsPreLoading(true)
    try {
      let requestData = {
        user_id: (selectedUserinfo?.user_id).toString(),
        order_status:"processing",
        product: "device_kit",
      };

      const createDiKitResponse = await createDiKit(requestData);
      getVerificationUserData(selectedUserinfo?.id)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPreLoading(true)
      getPremiumUserListData()
    }
  }

  const handleDeviceKitUpdate = async () =>{
    setIsPreLoading(true);

    if (
      !deviceKitOrderID?.trim() ||
      !deviceKitShipmentStatus?.trim() ||
      !deviceKitTrackingLink?.trim()
    ) {
      ShowNotification("info", "Please enter all values");
      setIsPreLoading(false); // Set loading state to false
      return;
    }

    let requestData = {
      kits: [
        {
          kit_id: (selectedUserinfo?.kits?.device_kit?.id).toString(),
          product: "device_kit",
          order_id: deviceKitOrderID,
          order_status: deviceKitShipmentStatus,
          order_tracking_link: deviceKitTrackingLink,
        }
      ],
      type: "device_kit",
    };
    try {
      const updateKitResponse = await updateDiKit(requestData);
      getVerificationUserData(selectedUserinfo?.id)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsDeviceEdit(false);
      setIsPreLoading(false);
      getPremiumUserListData()
    }
  }

  //Diagnostic Kit
  const handleKitUpdate = async () =>{
    setIsPreLoading(true)

    if (
      !stoolBarcode?.trim() ||
      !salivaBarcode?.trim() ||
      !kitOrderID?.trim() ||
      !kitShipmentStatus?.trim() ||
      !kitTrackingLink?.trim()
    ) {
      ShowNotification("info", "Please enter all values");
      setIsPreLoading(false); // Set loading state to false
      return;
    }

    let requestData = {
      kits: [
        {
          kit_id: (selectedUserinfo?.kits?.saliva_kit?.id).toString(),
          sample_type: "saliva",          
          product: "diagnostics_kit",
          barcode: salivaBarcode,
          order_id: kitOrderID,
          order_status: kitShipmentStatus,
          order_tracking_link: kitTrackingLink,
        },
        {
          kit_id: (selectedUserinfo?.kits?.stool_kit?.id).toString(),
          sample_type: "stool",
          product: "diagnostics_kit",
          barcode: stoolBarcode,
          order_id: kitOrderID,
          order_status: kitShipmentStatus,
          order_tracking_link: kitTrackingLink,
        },
      ],
      type: "diagnostics_kit",
    };
    try {
      const updateKitResponse = await updateDiKit(requestData);
      getVerificationUserData(selectedUserinfo?.id)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsDiagnosticsEdit(false);
      setIsPreLoading(false);
      getPremiumUserListData()
    }
  }

  const handleOrderUpdate = async () =>{
    setIsPreLoading(true);
    setIsPickupEdit(true);

    let requestData = {
      user_id: (selectedUserinfo?.user_id).toString(),
      tracking_link: orderTrackingLink,
      // order_id: orderOrderId,
      status:orderShipmentStatus
    }

    try {
      const updateOrderResponse = await updateDiOrder(requestData);
      getVerificationUserData(selectedUserinfo?.id)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPickupEdit(false);
      setIsPreLoading(false);
      getPremiumUserListData()
    }
    
  }

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }) : "";
  };


  return (
    <div>
      <Offcanvas
        className="premium-user-view"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Premium user information</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="premium-modal">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <Avatar
                      alt="Login User"
                      src={require("../../../assets/img/avatar-1.png")}
                      className="user-avatar mx-2"
                    />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">
                      User ID: {selectedUserinfo?.user_id}
                    </p>
                    <p className="value">
                      {selectedUserinfo?.customer_id}
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="mx-1"
                        style={{ color: "#4fa3cd" }}
                      />
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <FontAwesomeIcon icon={faUserPen} />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">Genebox Client Username</p>
                    <p className="value">
                      {selectedUserinfo?.genebox_client_username || "-"}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className="info">
                  <IconButton sx={{ p: 0 }} className="icon-button">
                    <FontAwesomeIcon icon={faIdCardClip} />
                  </IconButton>
                  <div className="content-section">
                    <p className="title">Genebox UserId</p>
                    <p className="value">{selectedUserinfo?.genebox_user_id || "-"}</p>
                  </div>
                </div>
              </Grid>

              {selectedUserinfo?.ring?.ring_size && (
                <>
                  <Grid item xs={6} md={6}>
                    <div className="info">
                      <IconButton sx={{ p: 0 }} className="icon-button">
                        <FontAwesomeIcon icon={faRing} />
                      </IconButton>
                      <div className="content-section">
                        <p className="title">Ring size</p>
                        <p className="value">
                          {selectedUserinfo?.ring?.ring_size}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div className="info">
                      <IconButton sx={{ p: 0 }} className="icon-button">
                        <FontAwesomeIcon icon={faPalette} />
                      </IconButton>
                      <div className="content-section">
                        <p className="title">Ring Color</p>
                        <p className="value">
                          {selectedUserinfo?.ring?.ring_color}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Card>

          {/* Diagnostics Kit */}
          {selectedUserinfo?.kits && (
            <section className="diagnostics-kit-view">
              <Card>
                <Card.Header className="kit-header">
                  <div>
                    <FontAwesomeIcon
                      icon={faPrescriptionBottleMedical}
                      className="mx-1"
                    />
                    Diagnostics Kit Shipment
                  </div>
                  <Chip
                    className="chip-border"
                    color={
                      selectedUserinfo?.kits?.stool_kit?.order_status ===
                      "delivered"
                        ? "success"
                        : selectedUserinfo?.kits?.stool_kit?.order_status ===
                          "processing"
                        ? "warning"
                        : "primary"
                    }
                    size="small"
                    variant="contained"
                    label={selectedUserinfo?.kits?.stool_kit?.order_status}
                  />
                </Card.Header>
                {!isDiagnosticsEdit ? (
                  <Card.Body className="pt-3">
                    <div className="order-info">
                      <p>
                        Order ID:
                        <b>{selectedUserinfo?.kits?.stool_kit?.order_id}</b>
                      </p>
                      <p>
                        Tracking link:
                          <a
                            href={
                              selectedUserinfo?.kits?.stool_kit
                                ?.order_tracking_link
                            }
                            target="_blank"
                          >
                            {
                              selectedUserinfo?.kits?.stool_kit
                                ?.order_tracking_link
                            }
                          </a>
                      </p>
                    </div>

                    <Grid container spacing={2} className="my-0">
                      <Grid item md={6} className="text-center">
                        <h6>
                          <b>{selectedUserinfo?.kits?.saliva_kit?.barcode}</b>
                        </h6>
                        <p>Saliva Barcode</p>
                      </Grid>
                      <Grid item md={6} className="text-center">
                        <h6>
                          <b>{selectedUserinfo?.kits?.stool_kit?.barcode}</b>
                        </h6>
                        <p>Stool Barcode</p>
                      </Grid>
                    </Grid>
                    
                    <div className="order-info">
                      <p>
                        Ordered at:{" "}
                        <b>
                          {selectedUserinfo?.kits?.stool_kit?.ordered_at
                            ? formatDate(
                                selectedUserinfo?.kits?.stool_kit?.ordered_at
                              )
                            : ""}
                        </b>
                      </p>

                      {selectedUserinfo?.kits?.stool_kit?.delivered_at && (
                        <p>
                          Delivered at:{" "}
                          <b>
                            {selectedUserinfo?.kits?.stool_kit?.delivered_at
                              ? formatDate(
                                  selectedUserinfo?.kits?.stool_kit
                                    ?.delivered_at
                                )
                              : ""}
                          </b>
                        </p>
                      )}
                    </div>
                    
                    {selectedUserinfo?.kits?.saliva_kit?.order_status !==
                      "delivered" && (
                      <div className="text-end">
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => {
                            handleDiagnosticsKitEditClick();
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} className="mx-1" />
                          Edit
                        </Button>
                      </div>
                    )}
                  </Card.Body>
                ) : (
                  <Card.Body className="pt-3">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className="my-0"
                          label="Stool Kit Barcode"
                          placeholder="Stool Kit Barcode"
                          size={"small"}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <FontAwesomeIcon icon={faBarcode} />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          value={salivaBarcode}
                          onChange={(e) => {
                            setSalivaBarcode(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          className="my-0"
                          label="Saliva Kit Barcode"
                          placeholder="Saliva Kit Barcode"
                          size={"small"}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                <FontAwesomeIcon icon={faBarcode} />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          value={stoolBarcode}
                          onChange={(e) => {
                            setStoolBarcode(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          className="my-0"
                          label="Kit Tracking Link"
                          size={"small"}
                          fullWidth
                          placeholder="Tracking link"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <FontAwesomeIcon icon={faLink} />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          value={kitTrackingLink}
                          onChange={(e) => {
                            setKitTrackingLink(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          className="my-0"
                          label="Order ID"
                          size={"small"}
                          fullWidth
                          placeholder="Order ID"
                          variant="outlined"
                          value={kitOrderID}
                          onChange={(e) => {
                            setKitOrderID(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth size={"small"} className="my-0">
                          <InputLabel id="status-select-label">
                            Kit Shipment Status
                          </InputLabel>
                          <Select
                            labelId="status-select-label"
                            label="Kit Shipment Status"
                            value={kitShipmentStatus}
                            onChange={(e) => {
                              setKitShipmentStaus(e.target.value);
                            }}
                          >
                            <MenuItem value="processing">Processing</MenuItem>
                            <MenuItem value="shipped">Shipped</MenuItem>
                            <MenuItem value="delivered">Delivered</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6} className="text-start my-auto">
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => {
                            setIsDiagnosticsEdit(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faClose} className="mx-1" />
                          Close
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={6} className="text-end my-auto">
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => {
                            handleKitUpdate();
                          }}
                        >
                          <FontAwesomeIcon icon={faSave} className="mx-1" />
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Card.Body>
                )}
              </Card>
            </section>
          )}

          {/* Diagnostics Kit - Sample pickup */}

          {selectedUserinfo?.is_first_kit_delivered &&
            !selectedUserinfo?.orders && (
              <section className="diagnostics-order-view">
                <Chip
                  className="chip-border chip-info"
                  color={"error"}
                  size="small"
                  variant="contained"
                  label={"Sample pickup is not scheduled"}
                />
              </section>
            )}

          {selectedUserinfo?.orders && (
            <section className="diagnostics-order-view">
              <section className="device-kit-view">
                <Card>
                  <Card.Header className="kit-header">
                    <div>
                      <FontAwesomeIcon icon={faTruckMedical} className="mx-1" />
                      Sample Pickup Collection
                    </div>
                    <Chip
                      className="chip-border"
                      color={
                        selectedUserinfo?.orders?.saliva?.status === "DONE"
                          ? "success"
                          : selectedUserinfo?.orders?.saliva?.status ===
                            "ASSIGNED"
                          ? "warning"
                          : "primary"
                      }
                      size="small"
                      variant="contained"
                      label={selectedUserinfo?.orders?.saliva?.status}
                    />
                  </Card.Header>
                  {!isPickupEdit ? (
                    <Card.Body className="pt-3">
                      <div className="order-info">
                        <p>
                          Appointment Date:
                          <b>
                            {selectedUserinfo?.orders?.saliva?.appointment_date
                              ? formatDate(
                                  selectedUserinfo?.orders?.saliva
                                    ?.appointment_date
                                )
                              : ""}
                          </b>
                        </p>
                        <p>
                          Order ID:
                          <b>
                            {selectedUserinfo?.orders?.saliva?.order_id && selectedUserinfo?.orders?.saliva?.order_id}
                          </b>
                        </p>
                        <p>
                          Tracking link:
                            <a
                              href={
                                selectedUserinfo?.orders?.saliva?.tracking_link
                              }
                              target="_blank"
                            >
                              {selectedUserinfo?.orders?.saliva?.tracking_link}
                            </a>
                        </p>
                        <p>
                          Last Update:
                          <b>
                            {selectedUserinfo?.orders?.saliva?.updated_at
                              ? formatDate(
                                  selectedUserinfo?.orders?.saliva?.updated_at
                                )
                              : ""}
                          </b>
                        </p>
                      </div>
                      {selectedUserinfo?.orders?.saliva?.status !==
                        "DONE" && (
                        <div className="text-end">
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                              handlePickupEditClick();
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mx-1" />
                            Edit
                          </Button>
                        </div>
                      )}
                    </Card.Body>
                  ) : (
                    <Card.Body className="pt-2">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="my-2"
                            label="Order Tracking Link"
                            size={"small"}
                            placeholder="Tracking link"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon icon={faLink} />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            value={orderTrackingLink}
                            onChange={(e) => {
                              setOrderTrackingLink(e.target.value);
                            }}
                          />
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                          <TextField
                            className="my-0"
                            label="Order Id"
                            size={"small"}
                            placeholder="Order Id"
                            fullWidth
                            variant="outlined"
                            value={orderOrderId}
                            onChange={(e) => {
                              setOrderOrderId(e.target.value);
                            }}
                          />
                        </Grid> */}

                        <Grid item xs={12} md={12}>
                          <FormControl
                            fullWidth
                            size={"small"}
                            className="my-0"
                          >
                            <InputLabel id="status-select-label">
                              Order Shipment Status
                            </InputLabel>
                            <Select
                              labelId="status-select-label"
                              label="Order Shipment Status"
                              value={orderShipmentStatus}
                              onChange={(e) => {
                                setOrderShipmentStaus(e.target.value);
                              }}
                            >
                              <MenuItem value="ASSIGNED">Assigned</MenuItem>
                              <MenuItem value="COLLECTED">Collected</MenuItem>
                              <MenuItem value="DONE">Done</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          className="text-start my-auto"
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              setIsPickupEdit(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faClose} className="mx-1" />
                            Close
                          </Button>
                        </Grid>

                        <Grid item xs={12} md={6} className="text-end my-auto">
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                              handleOrderUpdate();
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} className="mx-1" />
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Card.Body>
                  )}
                </Card>
              </section>
            </section>
          )}

          {/* Device Kit View */}
          {selectedUserinfo?.is_first_kit_delivered &&
            selectedUserinfo?.kits?.device_kit && (
              <section className="device-kit-view">
                <Card>
                  <Card.Header className="kit-header">
                    <div>
                      <FontAwesomeIcon icon={faBoxesPacking} className="mx-1" />
                      Device Kit Shipment
                    </div>
                    <Chip
                      className="chip-border"
                      color={
                        selectedUserinfo?.kits?.device_kit?.order_status ===
                        "delivered"
                          ? "success"
                          : selectedUserinfo?.kits?.device_kit?.order_status ===
                            "processing"
                          ? "warning"
                          : "primary"
                      }
                      size="small"
                      variant="contained"
                      label={selectedUserinfo?.kits?.device_kit?.order_status}
                    />
                  </Card.Header>
                  {!isDeviceEdit ? (
                    <Card.Body className="pt-3">
                      <div className="order-info">
                        <p>
                          Order ID:
                          <b>{selectedUserinfo?.kits?.device_kit?.order_id || "-"}</b>
                        </p>
                        <p>
                          Tracking link:
                            <a
                              href={
                                selectedUserinfo?.kits?.device_kit
                                  ?.order_tracking_link
                              }
                              target="_blank"
                            >
                              {
                                selectedUserinfo?.kits?.device_kit
                                  ?.order_tracking_link
                              }
                            </a>
                        </p>
                      </div>

                      <div className="order-info">
                        <p>
                          Ordered at:{" "}
                          <b>
                            {selectedUserinfo?.kits?.device_kit?.ordered_at
                              ? formatDate(
                                  selectedUserinfo?.kits?.device_kit?.ordered_at
                                )
                              : ""}
                          </b>
                        </p>

                        {selectedUserinfo?.kits?.device_kit?.delivered_at && (
                          <p>
                            Delivered at:{" "}
                            <b>
                              {selectedUserinfo?.kits?.device_kit?.delivered_at
                                ? formatDate(
                                    selectedUserinfo?.kits?.device_kit
                                      ?.delivered_at
                                  )
                                : ""}
                            </b>
                          </p>
                        )}
                      </div>
                      {selectedUserinfo?.kits?.device_kit?.order_status !==
                        "delivered" && (
                        <div className="text-end">
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                              handleDeviceKitEditClick();
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mx-1" />
                            Edit
                          </Button>
                        </div>
                      )}
                    </Card.Body>
                  ) : (
                    <Card.Body className="pt-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="my-0"
                            label="Kit Tracking Link"
                            size={"small"}
                            fullWidth
                            placeholder="Tracking link"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon icon={faLink} />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            value={deviceKitTrackingLink}
                            onChange={(e) => {
                              setDeviceKitTrackingLink(e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            className="my-0"
                            label="Order ID"
                            size={"small"}
                            fullWidth
                            placeholder="Order ID"
                            variant="outlined"
                            value={deviceKitOrderID}
                            onChange={(e) => {
                              setDeviceKitOrderID(e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControl
                            fullWidth
                            size={"small"}
                            className="my-0"
                          >
                            <InputLabel id="status-select-label">
                              Kit Shipment Status
                            </InputLabel>
                            <Select
                              labelId="status-select-label"
                              label="Kit Shipment Status"
                              value={deviceKitShipmentStatus}
                              onChange={(e) => {
                                setDeviceKitShipmentStaus(e.target.value);
                              }}
                            >
                              <MenuItem value="processing">Processing</MenuItem>
                              <MenuItem value="shipped">Shipped</MenuItem>
                              <MenuItem value="delivered">Delivered</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          className="text-start my-auto"
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              setIsDeviceEdit(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faClose} className="mx-1" />
                            Close
                          </Button>
                        </Grid>

                        <Grid item xs={12} md={6} className="text-end my-auto">
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                              handleDeviceKitUpdate();
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} className="mx-1" />
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Card.Body>
                  )}
                </Card>
              </section>
            )}

          {selectedUserinfo?.is_first_kit_delivered &&
            selectedUserinfo?.ring?.ring_size &&
            !selectedUserinfo?.kits?.device_kit && (
              <div className="text-center">
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleCreateDeviceKit();
                  }}
                >
                  Create Device Kit Order
                </Button>
              </div>
            )}
        </Offcanvas.Body>

        {/* <Offcanvas.Header className="footer">         
        </Offcanvas.Header> */}
      </Offcanvas>
    </div>
  );
};

export default PremiumUserInfo;

import { faBacon, faBreadSlice, faEdit, faPlusCircle, faPuzzlePiece, faWheatAwn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Offcanvas, Row } from "react-bootstrap";
import axios from "axios";
import "../food/NutritionView.scss";
import { TextField } from "@mui/material";
import MainContext from "../../../context/MainContext";
import NutritionContext from "../../../context/NutritionContext";
import { updateFoodGI } from "../../../utils/nutritionApi";

const FoodView = ({
  show,
  setNutrientShow,
  setShowAddFoodView,
  setIsManualFoodEdit
}) => {

  const {
    selectedFoodDetails,
    setSelectedFoodDetails,
    selectedFoodServing,
    setSelectedFoodServing,
    selectedFoodMicros,
    setTransferCCFoodData,
    foodListData, 
    setFoodListData,
    foodServingUnitArray
  } = useContext(NutritionContext);

  const { headers, ShowNotification, setIsPreLoading } = useContext(MainContext);

  const handleClose = () => {
    setNutrientShow(false)
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedServingAmount, setSelectedServingAmount] = useState("");
  const [servingOptions, setServingOptions] = useState([]);

  const [glycemicIndexValue, setGlycemicIndexValue] = useState("");
  const [glycemicIndexMutliplier, setGlycemicIndexMutliplier] = useState("");
  const [glycemicIndexAI, setGlycemicIndexAI] = useState("");

  const [isGIEditView, setIsGIEditView] = useState(false);  

  const { REACT_APP_BASE__URL } = process.env;

  //Add Glycemic Index for FatSecret
  const handleAddGlycemicIndex = async (food_id) => {    

    const requestData = {
      glycemic_index: glycemicIndexValue,
      glycemic_index_multiplier: glycemicIndexMutliplier,
      glycemic_index_ai: glycemicIndexAI,
    };
    const requestId = food_id;

    setIsPreLoading(true);

    try {
      const updateFoodResponse = await updateFoodGI(requestData, requestId);
  
      ShowNotification("success", "GI Updated Successfully");
      setSelectedFoodDetails((foodDetails) => ({
        ...foodDetails,
        glycemic_index: glycemicIndexValue,
        glycemic_index_multiplier: glycemicIndexMutliplier,
        glycemic_index_ai: glycemicIndexAI,
      }));

      setFoodListData(
        foodListData.map((food) => {
          if (food.id === food_id) {
            return {
              ...food,
              glycemic_index: glycemicIndexValue,
              glycemic_index_multiplier: glycemicIndexMutliplier,
              glycemic_index_ai: glycemicIndexAI,
            };
          } else {
            return food;
          }
        })
      );
      setIsGIEditView(false);

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.message)
    } finally {
      setIsPreLoading(false);
    }

    // Old
    // const apiUrl = `${REACT_APP_BASE__URL}/v2/calorie-counter/food/${food_id}`;
    // axios
    //   .patch(apiUrl, data, { headers })
    //   .then((response) => {
    //     ShowNotification("success", "GI Updated Succes");

    //     setSelectedFoodDetails((foodDetails) => ({
    //       ...foodDetails,
    //       glycemic_index: glycemicIndexValue,
    //       glycemic_index_multiplier: glycemicIndexMutliplier,
    //       glycemic_index_ai: glycemicIndexAI,
    //     }));

    //     setFoodListData(
    //       foodListData.map((food) => {
    //         if (food.id === food_id) {
    //           return {
    //             ...food,
    //             glycemic_index: glycemicIndexValue,
    //             glycemic_index_multiplier: glycemicIndexMutliplier,
    //             glycemic_index_ai: glycemicIndexAI,
    //           };
    //         } else {
    //           return food;
    //         }
    //       })
    //     );
    //     setIsGIEditView(false);
    //     // setFoodListData()
    //     // setGlycemicIndexValue("");
    //     // setGlycemicIndexMutliplier("");
    //     // setGlycemicIndexAI("");
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  
  /* Nutrient Update */
  const Nutrient = ({ name, value, percentage, unit = "mg", sub = false }) => (
    <div className={`flex-nutrient${sub ? " sub" : ""}`}>
      <span>{name}  &emsp;  {(value !== null) ? `${parseFloat(value).toFixed(1)} ${unit}` : "-"}</span>
      <span>{parseFloat(percentage).toFixed(0) + " %"}</span>
    </div>
  );

  const NutrientColumn = ({ nutrients }) => (
    <Col md={6} className="m-0 p-0">
      {nutrients.map((nutrient) => (
        <React.Fragment key={nutrient.key}>
          <Nutrient
            name={nutrient.name}
            value={selectedServingAmount * selectedFoodServing?.[nutrient.key]}
            percentage={((selectedServingAmount * selectedFoodServing?.[nutrient.key])/ selectedFoodMicros?.[nutrient.key] || 0)*100}
            unit={nutrient.unit}
            sub={nutrient.sub}
          />
          {nutrient.hr && <hr className="hr-1" />}
        </React.Fragment>
      ))}
    </Col>
  );

  const NutrientComponent = () => {
    const nutrientsLeft = [
      { name: "Total Fat", key: "fat", unit: "g" },
      { name: "Saturated Fat", key: "saturated_fat", sub: true, unit: "g" },
      { name: "Trans Fat", key: "trans_fat", sub: true, unit: "g"   },
      { name: "Polyunsaturated Fat", key: "polyunsaturated_fat", sub: true,  unit: "g" },
      { name: "Monounsaturated Fat", key: "monounsaturated_fat", unit: "g"   },
      { name: "Cholesterol", key: "cholesterol", unit: "mg" },
      { name: "Sodium", key: "sodium", unit: "mg" },
      { name: "Total Carbohydrate", key: "carbohydrate", unit: "g" },
      { name: "Dietary Fiber", key: "fiber", unit: "g", sub: true },
      { name: "Sugars", key: "sugar", unit: "g", sub: true },
    ];

    const nutrientsRight = [
      { name: "Protein", key: "protein", unit: "g"   },
      { name: "Vitamin D", key: "vitamin_d", unit: "mcg" },
      { name: "Calcium", key: "calcium", unit: "mg" },
      { name: "Iron", key: "iron", unit: "mg" },
      { name: "Potassium", key: "potassium", unit: "mg" },
      { name: "Vitamin A", key: "vitamin_a", unit: "mcg" },
      { name: "Vitamin C", key: "vitamin_c", unit: "mg" },
    ];

    return (
      <>
        <NutrientColumn nutrients={nutrientsLeft} />
        <NutrientColumn nutrients={nutrientsRight} />
      </>
    );
  };

  /* Top Micro Nutrient Update */
  const nutrients = [
    { label: 'Carbs', icon: faBreadSlice, color: 'Green', property: 'carbohydrate' },
    { label: 'Protein', icon: faBacon, color: 'Yellow', property: 'protein' },
    { label: 'Fat', icon: faPuzzlePiece, color: 'Red', property: 'fat' },
    { label: 'Fiber', icon: faWheatAwn, color: 'lightGreen', property: 'fiber' },
  ];
  
  // Nutrient component to display each individual nutrient
  const MicroNutrient = ({ label, value, icon, color }) => (
    <Col md={3} xs={3} className="text-center">
      <div className="item">
      <span style={{ fontSize: "24px" }}>
        {parseFloat(value).toFixed(1)}{" "}
        <span style={{ fontSize: "14px" }}>
          {selectedFoodServing?.metric_serving_unit}
        </span>
      </span>
      <br />
      <FontAwesomeIcon icon={icon} style={{ color }} /> {label}
      </div>
    </Col>
  );


  useEffect(() => {
    setServingOptions(selectedFoodDetails?.cc_servings);

    setGlycemicIndexValue(selectedFoodDetails?.glycemic_index);
    setGlycemicIndexMutliplier(selectedFoodDetails?.glycemic_index_multiplier);
    setGlycemicIndexAI(selectedFoodDetails?.glycemic_index_ai);

  }, [selectedFoodDetails]);

  useEffect(() => {
    if(selectedFoodServing){
      let defaultValue = selectedFoodServing["number_of_units"]/(selectedFoodServing["number_of_units"]);
      setSelectedServingAmount(defaultValue)
      setSelectedValue(selectedFoodServing['id'])
    }    
  }, [selectedFoodServing]);

  //Serving Description Change Update
  const handleServingDescChange = (event) => {
    const { value } = event.target;
    
    setSelectedFoodServing(
      servingOptions?.find((item) => item.id === value) || servingOptions[0]
    );
    setSelectedValue(value)
  };

  //Serving Value Change Update
  const handleServingAmountChange = (event) => {
    const { value } = event.target;
    let defaultValue = value/(selectedFoodServing["number_of_units"]);
    setSelectedServingAmount(defaultValue)
  };

  return (
    <div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>
              {selectedFoodDetails?.name}

              {selectedFoodDetails?.source === "MANUAL" ? (
                <div className="edit-food">
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => {
                    setTransferCCFoodData(selectedFoodDetails);
                    setIsManualFoodEdit(true);
                    handleClose();
                    setShowAddFoodView(true);
                  }}
                />
                </div>
              ) : (
                <>
                  {/* <FontAwesomeIcon
                    icon={faEdit}
                    className="mx-2 edit-food"
                    onClick={() => {
                      setIsGIEditView(!isGIEditView);
                    }}
                  /> */}
                </>
              )}
            </h5>
            <p>
              {selectedServingAmount *
                selectedFoodServing?.metric_serving_amount}{" "}
              {selectedFoodServing?.metric_serving_unit} -{" "}
              {selectedServingAmount * selectedFoodServing?.calories} Cals
            </p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="ScrollDesign view-food-info">
          {selectedFoodDetails && (
            <>
              {isGIEditView && <></>}
              <Row>
                <Col md={3} xs={4} className="my-3">
                  <TextField
                    type="number"
                    label="GI Value"
                    variant="outlined"
                    size="small"
                    min="0"
                    fullWidth
                    name="name"
                    value={
                      glycemicIndexValue || selectedFoodDetails?.glycemic_index
                    }
                    onChange={(e) => {
                      setGlycemicIndexValue(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3} xs={4} className="my-3">
                  <TextField
                    type="number"
                    label="GI Multiplier"
                    variant="outlined"
                    size="small"
                    min="0"
                    fullWidth
                    name="name"
                    value={
                      glycemicIndexMutliplier ||
                      selectedFoodDetails?.glycemic_index_multiplier
                    }
                    onChange={(e) => {
                      setGlycemicIndexMutliplier(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3} xs={4} className="my-3">
                  <TextField
                    type="number"
                    label="GI AI"
                    variant="outlined"
                    size="small"
                    min="0"
                    fullWidth
                    name="name"
                    value={
                      glycemicIndexAI || selectedFoodDetails?.glycemic_index_ai
                    }
                    onChange={(e) => {
                      setGlycemicIndexAI(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3} xs={12} className="my-3 my-auto">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="mx-2 viewBtn"
                    onClick={() => {
                      if (
                        glycemicIndexValue &&
                        glycemicIndexMutliplier &&
                        glycemicIndexAI
                      ) {
                        handleAddGlycemicIndex(selectedFoodDetails?.id); // food_id = cc_food.id
                      } else {
                        ShowNotification("warn", "Please Enter valid GI Value");
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add GI
                  </Button>
                </Col>
              </Row>
              <Row className="mb-1">
                {selectedFoodDetails?.glycemic_index ||
                selectedFoodDetails?.glycemic_index_multiplier ? (
                  <>
                    <Col md={4} xs={4}>
                      <div className="text-center border rounded border-success p-2 mb-3 bg-success text-white">
                        GI: {selectedFoodDetails?.glycemic_index || "-"}
                      </div>
                    </Col>
                    <Col md={4} xs={4}>
                      <div className="text-center border rounded border-success p-2 mb-3 bg-success text-white">
                        GI Mutliplier:{" "}
                        {selectedFoodDetails?.glycemic_index_multiplier || "-"}
                      </div>
                    </Col>
                    <Col md={4} xs={4}>
                      <div className="text-center border rounded border-success p-2 mb-3 bg-success text-white">
                        GI AI: {selectedFoodDetails?.glycemic_index_ai || "-"}
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>

              <Row className="mb-3 nutrient-top-card">
                {nutrients.map((nutrient) => (
                  <MicroNutrient
                    key={nutrient.label}
                    label={nutrient.label}
                    value={
                      selectedServingAmount *
                      selectedFoodServing?.[nutrient.property]
                    }
                    icon={nutrient.icon}
                    color={nutrient.color}
                  />
                ))}
              </Row>
              
              <Card className="mt-2">
                <Card.Header>Nutrition Facts</Card.Header>

                <Card.Body className="nutrient-section">
                  <div className="d-flex justify-content-between align-items-center font-1 m-0">
                    <span>
                      Serving Size{" "}
                      {selectedServingAmount *
                        selectedFoodServing?.number_of_units}{" "}
                      {selectedFoodServing?.measurement_description}
                    </span>

                    <span className="d-flex">
                      <Form.Select
                        size="sm"
                        onChange={handleServingAmountChange}
                        value={
                          selectedServingAmount *
                          selectedFoodServing?.number_of_units
                        }
                      >
                        {foodServingUnitArray?.map((option, index) => (
                          <option key={index} value={option}>
                            {parseFloat(option)}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Select
                        size="sm"
                        onChange={handleServingDescChange}
                        value={selectedValue}
                      >
                        {servingOptions?.map((option, index) => (
                          <option key={index} value={option?.id}>
                            {option?.measurement_description}
                          </option>
                        ))}
                      </Form.Select>
                    </span>
                  </div>
                  <hr className="hr-1"></hr>
                  Amount per Serving
                  <div className="font-1 mx-0 mb-0">
                    <span>
                      Calories{" "}
                      {selectedServingAmount * selectedFoodServing?.calories}{" "}
                      Cals
                    </span>
                  </div>
                  <hr className="hr-2"></hr>
                  <div className="text-end bold">% RDI*</div>
                  <Row className="m-0">
                    <NutrientComponent />
                  </Row>
                  <div className="bold text-end border-none">
                    * RDI value based on 2000 Cal diet for adult male
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default FoodView;

import { createContext } from "react";
//Components
import Home from "../components/home/Home";
import UserVerification from "../components/human-token/token-verification/UserVerification";
import KitListView from "../components/human-token/tracking/KitListView";
import OrderListView from "../components/human-token/tracking/OrderListView";
// import Dashboard from "../components/base/Dashboard";
import WhatsappQuery from "../components/whatsapp-query/WhatsappQuery";
import Food from "../components/nutrition/food/Food";
import Meal from "../components/nutrition/meal/Meal";
import MealTracker from "../components/nutrition/meal-tracker/MealTracker";
// import withRole from "../components/redirect/withRole";
// import AccessDenied from "../components/base/AccessDenied";
import Profile from "../components/profile/Profile";

/* Font Awesome */
import {
  faBell,
  faBowlFood,
  faBowlRice,
  faComments,
  faCubesStacked,
  faUser,
  faHomeAlt,
  faKitMedical,
  faTruckDroplet,
  faCompassDrafting,
  faEdit,
  faFingerprint,
  faUserShield,
  faUserTag,
  faUsers,
  faWheatAlt,
} from "@fortawesome/free-solid-svg-icons";
import HtmlEditor from "../components/email-template/HtmlEditor";
import RolePermissions from "../components/roles/RolePermissions";
import PremiumUsers from "../components/human-token/premium-users/PremiumUsers";
import Notifications from "../components/notifications/Notifications";

const DataContext = createContext({});

export const DataProvider = ({ children }) => { 

  const RoutesList = [
    {
      label: "Home",
      key: "",
      heading_name: "Dashboard",
      link: "/",
      icon: faHomeAlt,
      element: Home,
      accessRoles: "all",
    },
    {
      label: "Token User",
      heading_name: "Human Token",
      key: "token-user",
      icon: faUserTag,
      subMenu: [
        {
          label: "User Verification",
          key: "user-verify",
          link: "/user-verify",
          icon: faUsers,
          element: UserVerification,
          accessRoles: [4],
        },
        {
          label: "Paid Users",
          key: "paid-users",
          link: "/paid-users",
          icon: faUserShield,
          element: PremiumUsers,
          accessRoles: [4],
        },
      ],
    },
    // {
    //   label: "Tracking",
    //   key: "tracking",
    //   icon: faCompassDrafting,
    //   subMenu: [
    //     {
    //       label: "Kit",
    //       key: "kit",
    //       link: "/kit",
    //       icon: faKitMedical,
    //       element: KitListView,
    //       accessRoles: "all",
    //     },
    //     {
    //       label: "Pickup",
    //       key: "order",
    //       link: "/order",
    //       icon: faTruckDroplet,
    //       element: OrderListView,
    //       accessRoles: "all",
    //     },
    //   ],
    // },
    {
      label: "Whatsapp Queries",
      key: "queries",
      link: "/queries",
      icon: faComments,
      element: WhatsappQuery,
      accessRoles: [4],
    },
    {
      label: "App Notifications",
      key: "notifications",
      link: "/notifications",
      icon: faBell,
      element: Notifications,
      accessRoles: [4],
    },
    {
      label: "Nutrition",
      heading_name: "Nutrition",
      key: "nutrition",
      icon: faBowlFood,
      subMenu: [
        {
          label: "Food",
          key: "food",
          link: "/food",
          icon: faWheatAlt,
          element: Food,
          accessRoles: [5],
        },
        {
          label: "Meal",
          key: "meal",
          link: "/meal",
          icon: faBowlRice,
          element: Meal,
          accessRoles: [5],
        },
      ],
    },
    {
      label: "Food Intake Tracker",
      key: "food-intake",
      link: "/food-intake",
      icon: faCubesStacked,
      element: MealTracker,
      accessRoles: [5],
    },    
    {
      label: "Profile",
      key: "profile",
      heading_name: "Profile",
      link: "/profile",
      icon: faUser,
      element: Profile,
      accessRoles: "all",
      isNoSubNav: true,
    },
    // {
    //   label: "Roles & Permissions",
    //   key: "role-permisions",
    //   link: "/role-permisions",
    //   icon: faFingerprint,
    //   element: RolePermissions,
    //   accessRoles: "all",
    //   isNoSubNav: true,
    // },
    // {
    //   label: "Email Template",
    //   key: "html-editor",
    //   link: "/html-editor",
    //   icon: faEdit,
    //   element: HtmlEditor,
    //   accessRoles: "all",
    //   isNoSubNav: true,
    // },
  ];

  return (
    <DataContext.Provider value={{ RoutesList }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;

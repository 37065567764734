import { Grid } from '@mui/material';
import React, {  } from 'react'
import "./Home.scss"
const Home = () => {

  return (
    <div>
      <section>       

        <Grid container spacing={2}>
          
          <Grid item xs={6} md={3} sm={3}>
            <img
              src={require("../../assets/img/home/img-2.png")}
              alt=""
              className="home-image"
            />
          </Grid>
          <Grid item xs={6} md={3} sm={3}>
            <img
              src={require("../../assets/img/home/img-3.png")}
              alt=""
              className="home-image"
            />
          </Grid>
          <Grid item xs={6} md={3} sm={3}>
            <img
              src={require("../../assets/img/home/img-4.png")}
              alt=""
              className="home-image"
            />
          </Grid>
          <Grid item xs={6} md={3} sm={3}>
            <img
              src={require("../../assets/img/home/img-5.png")}
              alt=""
              className="home-image"
            />
          </Grid>         
        </Grid>
      </section>
    </div>
  );
}

export default Home
import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import MainContext from '../../../context/MainContext';
import { updateGBClientUsername } from '../../../utils/TrackingAPI';

const GeneBoxRegister = ({ show, setShow, userVerificationId, getPremiumUserListData, setSelectedHTUserID, selectedGeneboxClientName }) => {

  const handleClose = () => {
    setSelectedHTUserID("")
    setGeneboxUserName("")
    setShow(false)
  };

  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const [geneboxUserName, setGeneboxUserName] = useState("");

  useEffect(()=>{
    if(show){
      setGeneboxUserName(selectedGeneboxClientName)
    }else{
      setGeneboxUserName("")
    }
  },[show])

  const handleRegisterGBUsername = async () => {
    setIsPreLoading(true);

    try {
      let requestData = {
        ht_user_verification_id: userVerificationId.toString(),
        genebox_client_username: geneboxUserName,
      };
      const registerGBUserNameResponse = await updateGBClientUsername(
        requestData
      );
      getPremiumUserListData();
      handleClose();
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error",error?.message)
    } finally {
      setIsPreLoading(false);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ padding: "0.5rem 1.2rem" }}>
          <Modal.Title style={{ fontSize: "20px" }}>
            Register Genebox
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <TextField
              className="my-0"
              label="Genebox User Name"
              size={"small"}
              fullWidth
              placeholder="Genebox User Name"
              variant="outlined"
              value={geneboxUserName}
              onChange={(e) => {
                setGeneboxUserName(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleRegisterGBUsername();
            }}
          >
            Register
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GeneBoxRegister
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Offcanvas, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCheck,
  faClock,
  faCopy,
  faEnvelopeOpenText,
  faFileInvoice,
  faFingerprint,
  faHashtag,
  faIndianRupeeSign,
  faLink,
  faLinkSlash,
  faMobileScreenButton,
  faPaperPlane,
  faRefresh,
  faRetweet,
  faTriangleExclamation,
  faUser,
  faUserPen,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import countryCodesJson from '../../../json/country_codes.json';
import {
  addUser,
  sendPaymentLink,
  sendVerificationLink,
  updateUser,
} from "../../../utils/VerificationAPI";
import MainContext from "../../../context/MainContext";
import axios from "axios";
import { formatTimestamp } from "../../../commonFunctions";

const UserInfo = ({  
  show,
  setShow,
  isEditView,
  setIsEditView,
  isUserView,
  setIsUserView,
  selectedUserinfo,
  setSelectedUserinfo,
  getUserVerificationListData,
  getVerificationUserData
}) => {

  const { ShowNotification, setIsPreLoading } = useContext(MainContext);

  const handleClose = () => {
    setSelectedUserinfo([]);
    setAddressObj([]);

    setCommunicationAddress([]);
    setBillingAddress([]);
    setIsSameBillingAddress(false)

    setShow(false);
    setIsUserView(false);
    setIsAddressEdit(false);
    setIsEditView(false);
  };

  const [countryCodes, setCountryCodes] = useState(countryCodesJson);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");

  const [isVerificationDone, setIsVerificationDone] = useState(false);
  const [isVerificationExpired, setIsVerificationExpired] = useState(false);

  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isPaymentExpired, setIsPaymentExpired] = useState(false);

  //Set Verification User Conditions
  useEffect(() => {
    
    if(selectedUserinfo?.communication_address){
      let addressParse = JSON.parse(selectedUserinfo?.communication_address)
      setCommunicationAddress(addressParse)
    }      
    if(selectedUserinfo?.billing_address){
      let addressParse = JSON.parse(selectedUserinfo?.billing_address)
      setBillingAddress(addressParse)
    }    

    setName(selectedUserinfo?.name);
    setMobileNumber(selectedUserinfo?.mobile);
    setEmail(selectedUserinfo?.email);
    setDob(selectedUserinfo?.dob);
    setGender(selectedUserinfo?.gender);
    
    setSelectedCountryCode(countryCodes.find(country => country.dial_code === "+"+selectedUserinfo?.country_code))

    setIsVerificationDone(
      selectedUserinfo?.email_verified_at &&
        selectedUserinfo?.mobile_verified_at
    );

    setIsVerificationExpired(
      !selectedUserinfo?.email_verified_at &&
        !selectedUserinfo?.mobile_verified_at &&
        selectedUserinfo?.verification_expired_at
        ? new Date(selectedUserinfo.verification_expired_at) < new Date()
        : false
    );

    setIsPaymentDone(selectedUserinfo?.payment_at && selectedUserinfo?.payment_status === "SUCCESS");

    setIsPaymentExpired(
      !selectedUserinfo?.payment_at && selectedUserinfo?.payment_expired_at
        ? new Date(selectedUserinfo?.payment_expired_at) < new Date()
        : false
    );
  }, [selectedUserinfo]);

  useEffect(()=>{
    if(!isEditView){
      setName("");
      setMobileNumber("");
      setEmail("");
      setAge("")
      setDob("")
      setGender("")
    }else{
      setName(selectedUserinfo?.name);
      setMobileNumber(selectedUserinfo?.mobile);
      setEmail(selectedUserinfo?.email);

      setAge(selectedUserinfo?.age)
      setDob(selectedUserinfo?.dob)
      setGender(selectedUserinfo?.gender)

      if(selectedUserinfo?.communication_address){
        let addressParse = JSON.parse(selectedUserinfo?.communication_address)
        setCommunicationAddress(addressParse)
      }      
      if(selectedUserinfo?.billing_address){
        let addressParse = JSON.parse(selectedUserinfo?.billing_address)
        setBillingAddress(addressParse)
      }      
      
    }
  },[isEditView])


  /* Addres for payment */  
  const [isAddressEdit, setIsAddressEdit] = useState(false);  
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(false);  
  
  const [addressObj, setAddressObj] = useState({
    address_line1: "",
    address_line2: "",
    zipcode: "",
    city: "",
    state: "",
  });
  
  const [communicationAddress, setCommunicationAddress] = useState({
    address_line1: "",
    address_line2: "",
    zipcode: "",
    city: "",
    state: "",
  });

  const [billingAddress, setBillingAddress] = useState({
    address_line1: "",
    address_line2: "",
    zipcode: "",
    city: "",
    state: "",
  });

  const addressFields = [
    { label: 'Address Line 1', name: 'address_line1', size:"12" },
    { label: 'Address Line 2', name: 'address_line2', size:"12" },
    { label: 'City', name: 'city', size:"6" },
    { label: 'State', name: 'state', size:"6"  },
    { label: 'ZipCode', name: 'zipcode', size:"6"  },
  ];

  const userInputFields = [
    { label: 'Address Line 1', name: 'address_line1', size:"12" },
    { label: 'Address Line 2', name: 'address_line2', size:"12" },
    { label: 'City', name: 'city', size:"6" },
    { label: 'State', name: 'state', size:"6"  },
    { label: 'ZipCode', name: 'zipcode', size:"6"  },
  ];

  useEffect(() => {
    if(show){
      setSelectedCountryCode(countryCodes.find(country => country.code === "IN"))
    }

  },[show])

  const handleCheckboxChange = (event) => {
    const inputValue = event.target.checked;
    setIsSameBillingAddress(inputValue);
    if (inputValue) {
      setBillingAddress(communicationAddress);
    }
  };

  const handleAddressInputChange = (fieldName, value) => {

    if(fieldName === "zipcode"){
      const sanitizedInput = value.replace(/\D/g, '');
      if (sanitizedInput.length <= 6) {
        setAddressObj(prevAddressObj => ({
          ...prevAddressObj,
          [fieldName]: sanitizedInput,
        }));
      }
    }else{
      setAddressObj(prevAddressObj => ({
        ...prevAddressObj,
        [fieldName]: value,
      }));
    }
   
  };

  const handleCommnuicationAddressInputChange = (fieldName, value) => {

    if(fieldName === "zipcode"){
      const sanitizedInput = value.replace(/\D/g, '');
      if (sanitizedInput.length <= 6) {
        setCommunicationAddress(prevAddressObj => ({
          ...prevAddressObj,
          [fieldName]: sanitizedInput,
        }));
      }
    }else{
      setCommunicationAddress(prevAddressObj => ({
        ...prevAddressObj,
        [fieldName]: value,
      }));
    }
   
  };

  const handleBillingAddressInputChange = (fieldName, value) => {

    if(fieldName === "zipcode"){
      const sanitizedInput = value.replace(/\D/g, '');
      if (sanitizedInput.length <= 6) {
        setBillingAddress(prevAddressObj => ({
          ...prevAddressObj,
          [fieldName]: sanitizedInput,
        }));
      }
    }else{
      setBillingAddress(prevAddressObj => ({
        ...prevAddressObj,
        [fieldName]: value,
      }));
    }
   
  };

  //Create user 
  const handleCreateUser = async () => {

    if (name.trim() !== '' && mobileNumber && selectedCountryCode) {
      //New User
      setIsPreLoading(true);

      let requestData = {
        name: name,
        country_code: selectedCountryCode?.dial_code.slice(1),
        mobile: mobileNumber,
        email: email,
        dob:dob,
        gender:gender,
        // billing_address: billingAddress,
        // communication_address:communicationAddress
      };

      try {
        const addUserResponse = await addUser(requestData);
        getVerificationUserData(
          addUserResponse?.data?.user_detail?.verification_id
        );
        setIsEditView(false);
        setShow(true);
        setIsUserView(true);
        getUserVerificationListData();
        ShowNotification("success", addUserResponse?.message);
        debugger;
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        ShowNotification("error", error.message);
      } finally {
        setIsPreLoading(false);
      }
    }else{
      ShowNotification("warning", "Please check input Fields")
    }
   
  };

   //Update user 
  const handleUpdateUser = async () => {

    if (name.trim() !== "" && mobileNumber && email && selectedCountryCode) {
      setIsPreLoading(true);

      let requestData = {
        verification_id: selectedUserinfo?.verification_id,
        name: name,
        country_code: selectedCountryCode?.dial_code.slice(1),
        mobile: mobileNumber,
        email: email,
        dob:dob,
        gender:gender,
        // billing_address: billingAddress,
        // communication_address:communicationAddress
      };

      try {
        const updateUserResponse = await updateUser(requestData);
        getVerificationUserData(selectedUserinfo?.verification_id);
        ShowNotification("success", "User updated Successfully");
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        ShowNotification("error", error.message);
      } finally {
        setIsPreLoading(false);
        getUserVerificationListData();

        setIsEditView(false);
        setIsUserView(true);
      }
    } else {
      ShowNotification("warning", "Please check input Fields");
    }
  };

  //Send Verification link
  const handleSendVerificationLink = async () => {
    setIsPreLoading(true)

    let requestData = {
      verification_id: selectedUserinfo?.verification_id,
    };

    try {
      const sendVerificationLinkResponse = await sendVerificationLink(
        requestData
      );
      getVerificationUserData(selectedUserinfo?.verification_id);
      ShowNotification("success", "Verification link sent by user.");
    } catch (error) {
      ShowNotification("error", error?.errorMessage);
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsPreLoading(false)
    }
  };

  // Send Payment link
  const handleSendPaymentLink = async () => {
    setIsPreLoading(true);
    
    const requestData = {
      verification_id: selectedUserinfo?.verification_id,
    };

    try {
      const sendPaymentLinkResponse = await sendPaymentLink(
        requestData
      );
      ShowNotification("success", "Payment link sent by user.");

      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error",error.message)
    } finally {
      getVerificationUserData(selectedUserinfo?.verification_id);
      setIsPreLoading(false);
      setIsAddressEdit(false);
    }
    
  };

  const handleRenewPaymentLink = async () => {
    // setIsPreLoading(true);
    
    const requestData = {
      verification_id: selectedUserinfo?.verification_id,
      type:"renew"
    };
    
    try {
      const renewPaymentLinkResponse = await sendPaymentLink(
        requestData
      );
      ShowNotification("success", "Payment link successfully renewed and sent to the user.");

      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error",error.message)
    } finally {
      getVerificationUserData(selectedUserinfo?.verification_id);
      setIsPreLoading(false);
      setIsAddressEdit(false);
    }
    
  };

  /*  */

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      setFile(null);
      ShowNotification('warning','Please select a PDF file...');
      e.target.value = null;
    }

  };

  const handleInvoiceUpload = async () => {
    if (!file) {
      ShowNotification("warning", "Please select a file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);

      const apiUrl = "";

      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  /*  */

  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes.find(country => country.code === "IN"));
  
  const hanldleCountryCodeChange = (event, newValue) => {
    setSelectedCountryCode(newValue);
  };

  const ClipboardCopy = ({ value }) => {
    const [copyText, setCopyText] = useState("Click to copy");

    return (
      <Tooltip title={copyText}>
        <FontAwesomeIcon
          icon={faCopy}
          className="mx-2"
          style={{ cursor: "pointer", color: "blue"  }}
          onClick={() => {
            navigator.clipboard.writeText(value);
            setCopyText("Copied!");
            setTimeout(() => {
              setCopyText("Click to copy!");
            }, 2000);
          }}
        />
      </Tooltip>
    );
  };

  return (
    <div>
      <Offcanvas
        className="user-information"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {isUserView
              ? "View user info"
              : isEditView && selectedUserinfo?.verification_id
              ? "Update User"
              : "Create User"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            Customer ID:{" "}
            {selectedUserinfo?.customer_id ?(
              <b>
                {selectedUserinfo?.customer_id}{" "}
                <ClipboardCopy value={selectedUserinfo?.customer_id} />
              </b>
            ) : ""}
          </p>
          <p>
            Lead ID:{" "}
            <b>
              {selectedUserinfo?.lead_id}
              <ClipboardCopy value={selectedUserinfo?.lead_id} />
            </b>
          </p>

          {/* <div className="verification-source status-section">
            <p>Source</p>
            <Chip
              variant="filled"
              label={selectedUserinfo?.source === "CST" ? "CST" : "Typeform"}
              className={
                selectedUserinfo?.source === "CST" ? "cst" : "typeform"
              }
            />
          </div> */}

          {selectedUserinfo?.created_at && (
            <div className="paid-status status-section">
              <p>
                Created at: <b>{formatTimestamp(selectedUserinfo?.created_at)}</b>
              </p>
            </div>
          )}
          <div className="verified-status status-section">
            <p>Verification Status</p>
            <Chip
              variant="filled"
              label={
                selectedUserinfo?.mobile_verified_at
                  ? "Verified"
                  : "Not Verified"
              }
              className={
                selectedUserinfo?.mobile_verified_at
                  ? "Verified"
                  : "Not-Verified"
              }
            />
          </div>

          <div className="paid-status status-section">
            <p>Paid Status</p>
            <Chip
              variant="filled"
              label={
                selectedUserinfo?.payment_at &&
                selectedUserinfo?.payment_status === "SUCCESS"
                  ? "Paid"
                  : "Not Paid"
              }
              className={
                selectedUserinfo?.payment_at &&
                selectedUserinfo?.payment_status === "SUCCESS"
                  ? "Paid"
                  : "Not-Paid"
              }
            />
          </div>

          {selectedUserinfo?.payment_at && (
            <div className="paid-status status-section">
              <p>
                Payment at: <b>{formatTimestamp(selectedUserinfo?.payment_at)}</b>
              </p>
            </div>
          )}

          <div className="elegible-status status-section">
            <p>Eligible Status</p>
            <Chip
              variant="filled"
              label={
                selectedUserinfo?.is_eligible === null
                  ? "Not Yet"
                  : selectedUserinfo?.is_eligible
                  ? "Eligible"
                  : "Not Eligible"
              }
              className={
                selectedUserinfo?.is_eligible === null
                  ? "Not-Yet"
                  : selectedUserinfo?.is_eligible
                  ? "Eligible"
                  : "Not-Eligible"
              }
            />
          </div>
        </Offcanvas.Body>

        {/* Old  */}
        <Offcanvas.Body className="hide">
          {selectedUserinfo?.id && (
            <section className="info-section">
              <p className="user-name">
                <span>
                  <FontAwesomeIcon icon={faUser} className="mx-2" />
                  {selectedUserinfo?.name || "Token User"}
                </span>
                <span
                  onClick={() => {
                    if (selectedUserinfo?.verification_id) {
                      getVerificationUserData(
                        selectedUserinfo?.verification_id
                      );
                      getUserVerificationListData();
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className="mx-2"
                    style={{ color: "red", cursor: "pointer" }}
                  />
                </span>
              </p>
              <p className="info-item user-id">
                <span>
                  <FontAwesomeIcon icon={faHashtag} className="mr-1" />
                  Id : {selectedUserinfo?.id}
                </span>
                <small>{formatTimestamp(selectedUserinfo?.created_at)}</small>
              </p>
              {isUserView && (
                <>
                  <p className="info-item user-contact">
                    <span>
                      <FontAwesomeIcon icon={faMobileScreenButton} /> Mobile:
                      {" (+" +
                        (selectedUserinfo?.country_code
                          ? selectedUserinfo?.country_code
                          : "91") +
                        ") "}
                      {selectedUserinfo?.mobile}
                    </span>
                    <small
                      className={`${
                        selectedUserinfo?.mobile_verified_at
                          ? "verified"
                          : "not-verified"
                      }`}
                    >
                      {selectedUserinfo?.mobile_verified_at ? (
                        <>
                          <FontAwesomeIcon icon={faCheck} /> {"Verified"}
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faClock} />
                          {"Not Verify"}
                        </>
                      )}
                    </small>
                  </p>
                  <p className="info-item user-contact">
                    <span>
                      <FontAwesomeIcon icon={faEnvelopeOpenText} /> Email:{" "}
                      {selectedUserinfo?.email}
                    </span>
                    <small
                      className={`${
                        selectedUserinfo?.email_verified_at
                          ? "verified"
                          : "not-verified"
                      }`}
                    >
                      {selectedUserinfo?.email_verified_at ? (
                        <>
                          <FontAwesomeIcon icon={faCheck} /> {"Verified"}
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faClock} />
                          {"Not Verify"}
                        </>
                      )}
                    </small>
                  </p>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <p className="address-section-heading">
                        <FontAwesomeIcon icon={faAddressCard} />
                        {" Address: "}
                      </p>
                      <p className="address-section-details">
                        {communicationAddress && (
                          <>
                            {communicationAddress.address_line1 &&
                              `${communicationAddress.address_line1}, `}
                            {communicationAddress.address_line2 &&
                              `${communicationAddress.address_line2}, `}
                            <br />
                            {communicationAddress.city &&
                              `${communicationAddress.city}, `}
                            {communicationAddress.state &&
                              `${communicationAddress.state}, `}
                            <br />
                            {communicationAddress.zipcode &&
                              `PIN: ${communicationAddress.zipcode}.`}
                          </>
                        )}
                      </p>
                    </Grid>

                    {/* Billing Address */}
                    <Grid item md={6}>
                      <p className="address-section-heading">
                        <FontAwesomeIcon icon={faAddressCard} />{" "}
                        {"Billing Address: "}
                      </p>
                      <p className="address-section-details">
                        {billingAddress && (
                          <>
                            {billingAddress.address_line1 &&
                              `${billingAddress.address_line1}, `}
                            {billingAddress.address_line2 &&
                              `${billingAddress.address_line2}, `}
                            <br />
                            {billingAddress.city && `${billingAddress.city}, `}
                            {billingAddress.state &&
                              `${billingAddress.state}, `}
                            <br />
                            {billingAddress.zipcode &&
                              `PIN: ${billingAddress.zipcode}.`}
                          </>
                        )}
                      </p>
                    </Grid>
                  </Grid>
                </>
              )}
            </section>
          )}

          {isEditView && (
            <section className="input-section">
              <TextField
                className="input-field"
                label="Name"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value.replace(/[^\w\s]/gi, ""));
                }}
              />

              <FormControl fullWidth className="mb-3">
                <InputLabel id="select-label"></InputLabel>
                <Autocomplete
                  id="select-label"
                  options={countryCodes}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.dial_code})`
                  }
                  value={selectedCountryCode}
                  onChange={hanldleCountryCodeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Code"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <TextField
                className="input-field"
                label="Mobile Number"
                type="tel"
                variant="outlined"
                size="small"
                fullWidth
                value={mobileNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  const sanitizedInput = input.replace(/\D/g, "");
                  if (sanitizedInput.length <= 10) {
                    setMobileNumber(sanitizedInput);
                  }
                }}
              />

              <TextField
                className="input-field"
                label="E-Mail Address"
                type="mail"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              {/* <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input-field"
                    label="DOB"
                    type="date"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={dob}
                    onChange={(e) => {
                      setDob(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      label="Gender"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid> */}

              {/* Address Edit for User Creation */}
              <div className="hide">
                <Chip
                  label={"Address"}
                  color="success"
                  icon={
                    <FontAwesomeIcon icon={faAddressCard} className="mr-1" />
                  }
                  variant="filled"
                  className="input-header-chip"
                ></Chip>
                <p className="my-2">
                  <b>Communication Address</b>
                </p>
                <>
                  <Grid container spacing={2}>
                    {userInputFields.map((field) => (
                      <Grid item xs={12} md={field?.size} key={field?.name}>
                        <TextField
                          className=""
                          label={field.label}
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={communicationAddress[field?.name]}
                          onChange={(e) =>
                            handleCommnuicationAddressInputChange(
                              field?.name,
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>

                <p className="my-2">
                  <b>Delivery Address</b>
                </p>
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSameBillingAddress}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Same as communication address"
                  />
                  <Grid container spacing={2}>
                    {userInputFields.map((field) => (
                      <Grid item xs={12} md={field?.size} key={field?.name}>
                        <TextField
                          className=""
                          label={field.label}
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={billingAddress[field?.name]}
                          onChange={(e) =>
                            handleBillingAddressInputChange(
                              field?.name,
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              </div>
            </section>
          )}

          {isUserView && (
            <section className="info-section">
              <Card>
                <Chip
                  label={"Verification"}
                  color="success"
                  icon={
                    <FontAwesomeIcon icon={faFingerprint} className="mr-1" />
                  }
                  variant="filled"
                  className="header-chip"
                />
                <Card.Body>
                  <p className="info-item">
                    <span>
                      <FontAwesomeIcon icon={faUserShield} className="mr-1" />
                      Verification ID: {selectedUserinfo?.verification_id}
                      <ClipboardCopy value={selectedUserinfo?.verification_id} />
                    </span>
                  </p>

                  {!isVerificationDone &&
                    selectedUserinfo?.verification_expired_at && (
                      <p className="info-item">
                        <span>
                          <FontAwesomeIcon
                            icon={faLinkSlash}
                            className="mr-1"
                          />{" "}
                          Expires at:{" "}
                          {formatTimestamp(
                            selectedUserinfo?.verification_expired_at
                          )}
                        </span>
                      </p>
                    )}
                </Card.Body>
              </Card>

              {isPaymentDone && (
                <Card>
                  <Chip
                    label={"Payment Info"}
                    color="secondary"
                    icon={
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className="mr-1"
                      />
                    }
                    variant="filled"
                    className="header-chip"
                  />
                  <Card.Body>
                    {!isPaymentDone && selectedUserinfo?.payment_expired_at && (
                      <p className="info-item">
                        <span>
                          <FontAwesomeIcon icon={faLink} className="mr-1" />
                          {"Link: "}
                          <a
                            href={selectedUserinfo?.payment_link}
                            target="_blank"
                          >
                            {selectedUserinfo?.payment_link
                              ? selectedUserinfo?.payment_link //.slice(0, 50) +"..."
                              : ""}
                          </a>
                          <Tooltip title="Copy Payment link">
                            <FontAwesomeIcon
                              icon={faCopy}
                              className="mx-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  selectedUserinfo?.payment_link
                                );
                                ShowNotification(
                                  "info",
                                  "Payment Link Copied!"
                                );
                              }}
                            />
                          </Tooltip>
                        </span>
                      </p>
                    )}
                    {!isPaymentDone && selectedUserinfo?.payment_expired_at && (
                      <p className="info-item">
                        <span>
                          <FontAwesomeIcon
                            icon={faLinkSlash}
                            className="mr-1"
                          />
                          Expires at:{" "}
                          {formatTimestamp(
                            selectedUserinfo?.payment_expired_at
                          )}
                        </span>
                      </p>
                    )}
                    {isPaymentDone && (
                      <>
                        {selectedUserinfo?.payment_link ? (
                          <p className="info-item">
                            <span>
                              <FontAwesomeIcon
                                icon={faFileInvoice}
                                className="mr-1"
                              />
                              {"Invoice: "}
                              <a
                                href={selectedUserinfo?.payment_link}
                                target="_blank"
                              >
                                {selectedUserinfo?.payment_link
                                  ? selectedUserinfo?.payment_link //.slice(0, 50) +"..."
                                  : ""}
                              </a>
                              <Tooltip title="Copy Invoice link">
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  style={{ cursor: "pointer" }}
                                  className="mx-2"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      selectedUserinfo?.payment_link
                                    );
                                    ShowNotification(
                                      "info",
                                      "Invoice Link Copied!"
                                    );
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </p>
                        ) : (
                          <p className="info-item">&nbsp;</p>
                        )}
                        <p className="info-item">
                          <span>
                            <FontAwesomeIcon
                              icon={faFileInvoice}
                              className="mr-1"
                            />
                            Payment at:{" "}
                            {formatTimestamp(selectedUserinfo?.payment_at)}
                          </span>
                        </p>
                      </>
                    )}
                    {isPaymentDone && (
                      <div className="paid-status">
                        <img
                          src={require("../../../assets/img/paid-symbol.png")}
                          alt="paid"
                        />
                        {/* <span className="paid"> Paid</span> */}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              )}

              {isVerificationExpired && (
                <>
                  <Chip
                    label={"Verification Link Expired"}
                    color="error"
                    icon={
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="mr-1"
                      />
                    }
                    variant="filled"
                    className="header-chip"
                  />
                </>
              )}

              {isPaymentExpired && (
                <>
                  <Chip
                    label={"Payment Link Expired"}
                    color="error"
                    icon={
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="mr-1"
                      />
                    }
                    variant="filled"
                    className="header-chip"
                  />
                </>
              )}

              {/* Address Edit for Payment */}
              {isAddressEdit && (
                <>
                  <Grid container spacing={2}>
                    {addressFields.map((field) => (
                      <Grid item xs={12} md={field?.size} key={field?.name}>
                        <TextField
                          className="input-field"
                          label={field.label}
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={addressObj[field?.name]}
                          onChange={(e) =>
                            handleAddressInputChange(
                              field?.name,
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {isPaymentDone && (
                <>
                  {/* <Input
                    type="file"
                    onChange={handleFileChange}
                    // inputProps={{ accept: "application/pdf" }}
                    inputProps={{ accept: ".pdf" }}
                  /> */}
                </>
              )}
            </section>
          )}
        </Offcanvas.Body>

        <Offcanvas.Header className="footer hide">
          {isEditView && (
            <>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  if (selectedUserinfo?.verification_id) {
                    setIsUserView(true);
                  } else {
                    setShow(false);
                  }
                  setIsEditView(false);
                }}
              >
                Close Edit
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => {
                  if (selectedUserinfo?.verification_id) {
                    handleUpdateUser();
                  } else {
                    handleCreateUser();
                  }
                }}
              >
                {isEditView && selectedUserinfo?.verification_id
                  ? "Update User"
                  : "Save User"}
              </Button>
            </>
          )}

          {isUserView && (
            <>
              {/* Verification is not Done */}
              {!isVerificationDone && (
                <>
                  {!isVerificationExpired && (
                    <Button
                      variant="outlined"
                      color={
                        selectedUserinfo?.verification_expired_at
                          ? "warning"
                          : "primary"
                      }
                      onClick={handleSendVerificationLink}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} className="mr-1" />
                      {selectedUserinfo?.verification_expired_at
                        ? "Resend Verification Link"
                        : "Send Verification Link"}
                    </Button>
                  )}
                </>
              )}

              {/* Verification is Expired */}
              {isVerificationExpired && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleCreateUser();
                  }}
                >
                  <FontAwesomeIcon icon={faRetweet} className="mr-1" />
                  {"Renew User"} {/* For verification expired */}
                </Button>
              )}

              {/* Verification is Done & Payment is Not Done*/}
              {isVerificationDone && !isPaymentDone && (
                <>
                  {isPaymentExpired && (
                    <>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleRenewPaymentLink();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className="mr-1"
                        />
                        {"Renew Payment Link"} {/* For payment Expired */}
                      </Button>
                    </>
                  )}
                  {!isPaymentExpired && (
                    <>
                      {isAddressEdit ? (
                        <>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              setIsAddressEdit(false);
                            }}
                          >
                            {"Close Edit"}
                          </Button>
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleSendPaymentLink();
                            }}
                          >
                            {"Update & Send Link"}
                          </Button>
                        </>
                      ) : (
                        <>
                          {!selectedUserinfo?.payment_expired_at ? (
                            <>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  // setIsAddressEdit(true);
                                  handleSendPaymentLink();
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="mr-1"
                                />
                                {"Send payment link"}
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                handleSendPaymentLink();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="mr-1"
                              />
                              {"Resend Payment Link"}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Payment is not Done */}
              {!isVerificationDone && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsUserView(false);
                      setIsEditView(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faUserPen} className="mr-1" /> Edit
                    Info
                  </Button>
                </>
              )}

              {/* Payment is Done */}
              {/* {isPaymentDone && (
                <Chip
                  variant="filled"
                  label={"Payment Done"}
                  color="success"
                  className="payment-done-chip"
                />
              )} */}
            </>
          )}
        </Offcanvas.Header>

        {/* Old */}
      </Offcanvas>
    </div>
  );
};

export default UserInfo;

import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;

const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      access_token: cookies.get("access_token"),
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

const userVerificationApi = createAxiosInstance(REACT_APP_BASE_URL);

//Interceptors
const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["access_token"] = token; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(userVerificationApi);

export const getUserVerificationList = (requestData) =>
  new Promise((resolve, reject) => {
    userVerificationApi
      .get(`/v3/int/human-token/verification/list`, { params: requestData })
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getVerificationUser = (requestData) =>
  new Promise((resolve, reject) => {
    userVerificationApi
      .get(`/v3/int/human-token/verification/user`, { params: requestData })
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const addUser = (requestData) =>
  new Promise((resolve, reject) => {
    userVerificationApi
      .post(`/v3/int/human-token/user/add`, requestData)
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const updateUser = (requestData) =>
  new Promise((resolve, reject) => {
    userVerificationApi
      .put(`/v3/int/human-token/user/update`, requestData)
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendVerificationLink = (requestData) =>
  new Promise((resolve, reject) => {
    userVerificationApi
      .post(`/v3/int/human-token/verification/link`, requestData)
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendPaymentLink = (requestData) =>
  new Promise((resolve, reject) => {
    userVerificationApi
      .post(`/v3/int/human-token/payment/link`, requestData)
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });


export { userVerificationApi };

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClose,
  faEdit,
  faEye,
  faFileLines,
  faRefresh,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import {
  Divider,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import "../food/NutritionHome.scss";
import CreateMeal from "./CreateMeal";
import MealView from "./MealView";
import { getCCMealData } from "../../../utils/nutritionApi";
import MainContext from "../../../context/MainContext";
import NutritionContext from "../../../context/NutritionContext";

const Meal = () => {

    const {
      mealListData,
      setMealListData,
      initialRenderMeal,
      setInitialRenderMeal,
    } = useContext(NutritionContext);

    const {
      setIsPreLoading
    } = useContext(MainContext);

    const [initComp, setInitComp] = useState(true);
    const [mealFullData, setMealFullData] = useState([]);

    const [isMealEdit, setIsMealEdit] = useState(false);
    const [selectedMealSearch, setSelectedMealSearch] = useState("");

    const [showMealCreationView, setShowMealCreationView] = useState(false);
    const [showMealFullView, setShowMealFullView] = useState(false);

    const fetchMealListData = async () => {
      setIsPreLoading(true);
      try {
        let requestData = {
          "limit": 10,
          "food_profile_id": null,
          "is_admin": true,
          "offset": 0,
          "search": ""
        }
  
        const CCMealResponse = await getCCMealData(requestData);
  
        let responseData = CCMealResponse?.data?.meal;
        setMealListData(responseData?.cc_meal)
        
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        setIsPreLoading(false);
      } finally {
        setIsPreLoading(false);
      }
    };
  
    const mealListColumns = [
      {
        name: "ID",
        selector: (row) => parseInt(row.id),
        key: "id",
        width: "10%",
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        key: "name",
        width: "20%",
        sortable: true,
      },
      {
        name: "Food List",
        key: "",
        width: "50%",
        cell: (row) => (
          <>
            <div>
              {row?.cc_meal_serving?.map((serving, index) => {
                const {
                  cc_serving: {
                    cc_food: { name },
                    metric_serving_amount,
                    metric_serving_unit,
                    calories,
                    number_of_units,
                    // measurement_description,
                  },
                } = serving;

                const unit_count = serving.number_of_units / number_of_units; //serving?.cc_serving?.number_of_units

                const adjustedUnits =
                  unit_count * serving?.cc_serving?.metric_serving_amount;

                const adjustedCalories = unit_count * calories;

                return (
                  <div key={name + index} className="mb-1">
                    <span>{name} - </span>
                    <small>
                      {"["} {adjustedUnits} {metric_serving_unit}{" "}
                      {metric_serving_amount && "- "}
                    </small>
                    <small>
                      {adjustedCalories} Cals {"]"}
                    </small>
                  </div>
                );
              })}
            </div>
          </>
        ),
      },
      {
        name: "Edit",
        width: "20%",
        cell: (row) => (
          <div className="text-center">
            <Button
              variant="outline-primary"
              size="sm"
              className="mx-2 viewBtn"
              onClick={() => {
                setMealFullData(row);
                setIsMealEdit(true);
                setShowMealCreationView(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} /> Edit
            </Button>
            <Button
              variant="outline-success"
              size="sm"
              className="mx-2 viewBtn"
              onClick={() => {
                setMealFullData(row);
                setShowMealFullView(true);
              }}
            >
              <FontAwesomeIcon icon={faEye} /> View
            </Button>
          </div>
        ),
      },
    ];

    /* Loading Based on changes */

    // useEffect(() => {
    //   if (!initialRenderMeal && !initComp) {
    //     fetchMealListData();
    //   }
    // }, [mealListParam]);
    
  useEffect(() => {
    if (initialRenderMeal) {
      fetchMealListData();
      setInitialRenderMeal(false);
    }

    setTimeout(() => {
      setInitComp(false);
    }, 1000);
  }, []);
   

  return (
    <section>
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> Meal List
      </section>

      <Row className="m-0 mt-3">
        <Col md={6} className="text-start"></Col>
        <Col md={6} className="text-end meal-list search-section">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="food-search-section"
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="py-2 food-search-clear"
              // onClick={() => {fetchMealListData();}}
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Meal"
              inputProps={{ "aria-label": "Search Meal" }}
              value={selectedMealSearch}
              onChange={(e) => setSelectedMealSearch(e.target.value)}
            />

            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="py-2 food-search-clear"
              onClick={() => {
                setSelectedMealSearch("");
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Paper>

          <Button
            variant="outline-success"
            color="success"
            className=" mx-1"
            style={{
              textTransform: "none",
              borderRadius: "5px",
              padding: "5px 10px",
            }}
            onClick={() => {
              setShowMealCreationView(true);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} className="mx-1" />{" "}
            <span>Create Meal</span>
          </Button>

        
          <Button
            variant="outline-secondary"
            color="secondary"
            className=" mx-1"
            style={{
              textTransform: "none",
              borderRadius: "5px",
              padding: "5px 10px",
            }}
            onClick={() => {
              fetchMealListData();
            }}
          >
            <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
            <span>Refresh</span>
          </Button>
        </Col>
      </Row>

      <div className="dt-section meal-dt p-0 mt-3">
        <DataTable
          columns={mealListColumns}
          striped
          // data={mealListData}
          data={mealListData?.filter((meal) =>
            meal?.name
              ?.toLowerCase()
              .includes(selectedMealSearch?.toLowerCase())
          )}
          pagination
          searchable
        />
      </div>

      <CreateMeal
        show={showMealCreationView}
        setShowMealCreationView={setShowMealCreationView}
        mealFullData={mealFullData}
        setIsMealEdit={setIsMealEdit}
        isMealEdit={isMealEdit}
        fetchMealListData={fetchMealListData}
      />

      <MealView
        show={showMealFullView}
        setShowMealFullView={setShowMealFullView}
        mealFullData={mealFullData}
      />
    </section>
  );
};

export default Meal;

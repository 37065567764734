import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClose, faFileLines, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Divider, Grid, IconButton, InputBase, Paper } from "@mui/material";
import { Card, Button } from "react-bootstrap";

import './Notifications.scss'
import SendManualNotification from './SendManualNotification';

const Notifications = () => {
  

  const [show, setShow] = useState(false);

  return (
    <div className="in-app-notifications">
      <section className="component-heading mt-1 mb-3">
        <FontAwesomeIcon icon={faFileLines} className="mx-2" /> App
        Notifications
      </section>
      <Grid container spaing={0} className="mb-2">
        <Grid item md={4} xs={6} className="text-end search-section">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="kit-search-section"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search User"
              inputProps={{ "aria-label": "Search User" }}
            />

            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="py-2 search-clear"
              onClick={() => {}}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              className="py-2 food-search-clear"
              onClick={() => {
                // setIsSearchParam(true);
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item md={8} xs={6} className="text-end top-action-button-group">
          <div className="ml-auto">
            <Button
              variant="outline-secondary"
              color="secondary"
              className=" mx-1"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {}}
            >
              <FontAwesomeIcon icon={faRefresh} className="mx-1" />{" "}
              <span>Refresh</span>
            </Button>
            <Button
              variant="outline-primary"
              color="secondary"
              className=" mx-1"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => {
                setShow(true);
              }}
            >
              <FontAwesomeIcon icon={faBell} className="mx-1" />
              <span>Send Notification</span>
            </Button>
          </div>
        </Grid>
      </Grid>

      <section>
        <div className='notification-view-block'>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Card>
                <Card.Header className="text-start">UserList</Card.Header>
                <section className="notification-users-list ScrollDesign">
                  {[...Array(5)].map((_, index) => (
                    <div className="chat-card" key={index}>
                      <Grid container spacing={2}>
                        <Grid item md={2} xs={2} className="image">
                          <Avatar
                            alt="Login User"
                            src={require("../../assets/img/avatar-1.png")}
                            className="user-avatar"
                          />
                        </Grid>
                        <Grid item md={10} xs={10}>
                          <div className="chat-content">
                            <p className="user-id">
                              User ID: <b>001 (1234567) </b>
                            </p>
                            <p className="message">
                              Sample notification message for app, Sample
                              notification message for app
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </section>
              </Card>
            </Grid>
            <Grid item md={8}>
              <Card>
                <Card.Header className="text-start">
                  Recent Sent Notifications
                </Card.Header>
                <Card.Body className="recent-chat-list ScrollDesign">
                  {[...Array(5)].map((_, index) => (
                    <div className="sent-item-card" key={index}>
                      <Card>
                        <div className="sent-message-item">
                          <div className="image">
                            <Avatar
                              alt="Login User"
                              src={require("../../assets/img/notification-icon.png")}
                              className="user-avatar"
                            />
                          </div>
                          <div className="content-block">
                            <h4 className="title">
                              {"Notification Title"}{" "}
                              <small>09-Apr-2024 17:40</small>
                            </h4>
                            <p className="message">
                              {"Sample notification message for app"}
                            </p>
                          </div>
                        </div>
                        <span className="sent-info">
                          <div>
                            <p>
                              User ID: <b>001 (1234567) </b>
                            </p>
                            <span className="notification-id">#117</span>
                          </div>
                          <span className="category">type</span>
                        </span>
                      </Card>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
          <div></div>
        </div>
      </section>

      <SendManualNotification show={show} setShow={setShow} />
    </div>
  );
}

export default Notifications
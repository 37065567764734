export const capitalized=(word) =>{
    return word?.charAt(0).toUpperCase()
    + word?.slice(1)
}

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();

    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    // const ss = String(date.getSeconds()).padStart(2, "0");

    return (
      <span>
        {`${dd}-${mm}-${yyyy}`} <small>{`${hh}:${min}`}</small>
        {/* ${ss} */}
      </span>
    );
  }

export const formatTimestampWithDoubleLine = (timestamp) => {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();

    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    // const ss = String(date.getSeconds()).padStart(2, "0");

    return (
      <span>
        {`${dd}-${mm}-${yyyy}`} <br/><small>{`${hh}:${min}`}</small>
        {/* ${ss} */}
      </span>
    );
  }

  
